<template>
  <!-- 搜证设定-幕 -->
  <div class="evidenceAct" v-loading="finshLoading">
    <!-- 行动点数 -->
    <div style="display: flex; align-items: center">
      <div class="tab-title" style="width: 100px; margin-right: 20px">
        行动点数
      </div>
      <!-- <el-input-number v-model="model.actionPoints" size="mini" controls-position="right" :min="0" :max="50"></el-input-number> -->
      <span style="font-size: 14px; margin: 0 10px"
        >总消耗行动点：{{ allEcpendPoint() }}</span
      >
      <span style="font-size: 14px"
        >建议行动点：{{ allEcpendPoint() / chapterPersonList.length }}</span
      >
    </div>

    <!-- 个人行动点数 -->
    <div
      v-if="refreshPoint"
      style="
        display: flex;
        align-items: center;
        margin-top: 18px;
        align-items: center;
        font-size: 14px;
        height:20px
      "
    >
      <div
        v-for="(item, i) in model.personActionPoints"
        :key="i"
        style="margin-right: 30px"
      >
        {{ rePersonName(item.scenarioPersonId) }}:
        <input
          v-model="item.actionPoint"
          type="number"
          style="
            margin-left: 5px;
            width: 60px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #7f76bd;
          "
        />
      </div>
    </div>

  

    <el-divider />

    <div class="row1">
      <div class="left-map">
        <!-- -----------------------------------------地图 -->
        <div class="tab-title">地图</div>
        <div class="evidenceMap" style="margin-top: 10px">
          <div
            class="map-mark"
            v-if="!rightClueList.length && isshowmark"
          ></div>
          <div class="rowTop">
            <choice-image :text="`地图图片`" @surePush="changeBackground" />
            <el-select
              v-model="imgHeight"
              size="mini"
              style="width: 100px"
              placeholder="地图大小"
            >
              <el-option :label="`376`" :value="'376px'"></el-option>
              <el-option :label="`667`" :value="'667px'"></el-option>
            </el-select>

            <el-select
              v-model="model.showStatus"
              size="mini"
              style="width: 100px"
              placeholder="地点样式"
            >
              <el-option :label="`全显示`" :value="1"></el-option>
              <el-option :label="`仅名称`" :value="2"></el-option>
              <el-option :label="`仅图片`" :value="3"></el-option>
            </el-select>

            <el-select
              v-model="model.showStyle"
              size="mini"
              style="width: 100px"
              placeholder="地点样式"
            >
              <el-option :label="`风格1`" :value="1"></el-option>
              <el-option :label="`风格2`" :value="2"></el-option>
              <el-option :label="`风格3`" :value="3"></el-option>
              <el-option :label="`风格4`" :value="4"></el-option>
            </el-select>

            <el-dropdown @command="copyMap" trigger="click">
              <span
                class="el-dropdown-link"
                style="
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  line-height: 28px;
                  color: #7f76bd;
                "
              >
                复制地图<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="(item, i) in mList"
                  :key="i"
                >
                  第{{ item.chapterNum }}幕
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button @click="addPlace" type="primary" size="mini">
              添加地点
            </el-button>
            <!-- 地图锁 -->
            <div class="mapLock">
              <el-button
                @click="mapLock = !mapLock"
                circle
                size="mini"
                :type="mapLock ? 'primary' : ''"
              >
                <i
                  v-if="mapLock"
                  style="font-size: 20px"
                  class="el-icon-lock"
                ></i>
                <i v-else style="font-size: 20px" class="el-icon-unlock"></i>
              </el-button>
            </div>
          </div>
          <div class="content">
            <!-- 首先图片的高度是变化的，高度变化的同时宽度肯定会跟随着变化，所以定位，要以背景图高度为准 -->
            <div class="fatherDiv" id="father">
              <!-- 背景图 -->
              <div
                v-if="model.pictureVo"
                @click.stop
                class="sonImg"
                :style="`
                        width: calc(${imgHeight} * ${model.aspectRatio});
                        height: ${imgHeight};
                        background-image: url(${model.pictureVo.picturePath});
                        background-repeat: no-repeat;
                        background-size: 100% ;
                      `"
              ></div>
              <!-- 内容 -->
              <div
                class="sonItem"
                v-for="(item, i) in model.mapList"
                :key="i"
                :id="`${i}`"
                v-drag
                @click="choicePlace = i"
                :style="{
                  position: 'absolute',
                  top: item.topLocal,
                  left: item.leftLocal,
                  width: item.width,
                  padding: item.pictureVo.picturePath ? '' : '2px',
                  borderRadius: item.pictureVo.picturePath ? '' : '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color:
                    (item.interiorPicturePath || item.picturePath) && item.relevanceClueNum
                      ? ''
                      : 'rgb(243, 148, 172)',
                }"
              >
                <!-- 外观图 -->
                <img
                  v-if="
                    item.pictureVo.picturePath &&
                    (model.showStatus === 1 || model.showStatus === 3)
                  "
                  class="item-img"
                  :src="item.pictureVo.picturePath"
                  :style="`
                            width: 100%; height: 100%;
                            pointer-events: none;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            `"
                />

                <!-- 文字和按钮 -->
                <div
                  v-if="model.showStatus === 1"
                  class="item-text"
                  :style="{
                    bottom: imgHeight === '376px' ? '-18px' : '-31px',
                    color:
                      (item.interiorPicturePath || item.picturePath) && item.relevanceClueNum
                        ? ''
                        : 'rgb(243, 148, 172)',
                  }"
                >
                  <img
                    :src="
                      model.showStyle === 1
                        ? border1
                        : model.showStyle === 2
                        ? border2
                        : model.showStyle === 3
                        ? border3
                        : border4
                    "
                    :style="`width: ${
                      imgHeight === '376px' ? '58.5px' : '117px'
                    }; height: ${imgHeight === '376px' ? '18px' : '32px'};`"
                  />
                  <div
                    class="item-text-content"
                    :style="`width: ${
                      imgHeight === '376px' ? '58.5px' : '117px'
                    };
                              height: ${
                                imgHeight === '376px' ? '18px' : '32px'
                              };
                              line-height: ${
                                imgHeight === '376px' ? '18px' : '32px'
                              };
                              font-size: ${
                                imgHeight === '376px' ? '12px' : '20px'
                              };
                              `"
                  >
                    {{ item.clueName }}
                  </div>
                </div>

                <!-- 仅文字 -->
                <div
                  class="item-only-text"
                  v-if="model.showStatus === 2"
                  :style="`bottom: ${
                    imgHeight === '376px' ? '-18px' : '-31px'
                  }`"
                >
                  <img
                    :src="
                      model.showStyle === 1
                        ? border1
                        : model.showStyle === 2
                        ? border2
                        : model.showStyle === 3
                        ? border3
                        : border4
                    "
                    :style="`width: ${
                      imgHeight === '376px' ? '58.5px' : '117px'
                    }; height: ${imgHeight === '376px' ? '18px' : '32px'};`"
                  />
                  <div
                    class="item-text-content"
                    :style="`width: ${
                      imgHeight === '376px' ? '58.5px' : '117px'
                    };
                              height: ${
                                imgHeight === '376px' ? '18px' : '32px'
                              };
                              line-height: ${
                                imgHeight === '376px' ? '18px' : '32px'
                              };
                              font-size: ${
                                imgHeight === '376px' ? '12px' : '20px'
                              };
                              `"
                  >
                    {{ item.clueName }}
                  </div>
                </div>

                <div class="item-num">{{ item.relevanceClueNum }}</div>

                <div class="sonItem-btn">
                  <i
                    @click="editPlace(item)"
                    class="el-icon-edit"
                    style="margin-right: 10px; color: #7f76bd"
                  ></i>
                  <i
                    @click="deletePlace(item, i)"
                    class="el-icon-circle-close"
                    style="color: red"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- -----------------------------------------人物 -->
        <div class="tab-title">人身线索</div>
        <div style="display: flex; margin-top: 10px">
          <div
            style="
              margin-right: 10px;
              padding: 4px;
              border: 1px solid #c5c0e0;
              border-radius: 4px;
              position: relative;
            "
            v-for="(item, i) in chapterPersonList"
            :key="i"
          >
            <div
              style="
                border-radius: 50%;
                background: #b4d4f4;
                font-size: 12px;
                width: 15px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -5px;
                left: -5px;
              "
            >
              {{ item.clueNum }}
            </div>
            {{ item.personName }}
            <i
              @click="editPeople(item)"
              class="el-icon-edit"
              style="cursor: pointer; margin-left: 30px; color: #7f76bd"
            ></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="tips flex-wrap">
          注：
          <span class="ball" style="background: #feebae"></span>
          <span style="margin-left: 5px">地点线索</span>
          <span class="ball" style="background: #b4d4f4"></span>
          <span style="margin-left: 5px">人身线索</span>
          <span class="ball" style="background: #abe3de"></span>
          <span style="margin-left: 5px">隐藏线索</span>
          <span
            class="ball"
            style="background: #fff; border: 1px solid #c5c0e0"
          ></span>
          <span style="margin-left: 5px">待绑定线索</span>
          <span class="m-l-10" style="color: #f394ac; font-size: 18px">01</span>
          <span style="margin-left: 5px">未绑定图片的线索</span>
          <span style="margin-left: 35px"
            >音频设置中的“结束阶段”表示公投以后直到结束退出的：“查看真相”、“查看排名”、“退出并评价”几个环节。</span
          >
        </div>
        <!-- 每一幕管理的倒计时 -->
        <el-divider></el-divider>
        <div class="tab-title" style="margin-top: 20px">时间设置</div>
        <div class="time">
          <div style="margin-right: 10px">
            阅读:
            <input
              v-model="model.scenarioChapterCountDownVo.readTime"
              type="number"
            />
          </div>
          <div style="margin-right: 10px">
            自我介绍:
            <input
              v-model="model.scenarioChapterCountDownVo.selfIntroductionTime"
              type="number"
            />
          </div>
          <div style="margin-right: 10px">
            搜证:
            <input
              v-model="model.scenarioChapterCountDownVo.searchTime"
              type="number"
            />
          </div>
          <div style="margin-right: 10px">
            讨论:
            <input
              v-model="model.scenarioChapterCountDownVo.discussTime"
              type="number"
            />
          </div>
          <div style="margin-right: 10px">
            答题:
            <input
              v-model="model.scenarioChapterCountDownVo.answerTime"
              type="number"
            />
          </div>
          <div v-if="chapterNum === mList.length">
            公投:
            <input
              v-model="model.scenarioChapterCountDownVo.voteTime"
              type="number"
            />
          </div>
        </div>
        <el-divider></el-divider>
        <div class="tab-title" style="margin-top: 20px">音频设置</div>
        <div class="flex-c mt-3">
          <div class="flex-c">
            <div class="label-pre mr-2"></div>
            <div>录入设置：</div>
          </div>
          <div class="flex-c ml-4">
            <div @click="model.chapterAudioDTO.flag = 0" class="my-radio">
              <div
                v-if="model.chapterAudioDTO.flag === 0"
                class="my-radio-choice"
              ></div>
            </div>
            <div class="font-2">整幕统一</div>
            <div @click="model.chapterAudioDTO.flag = 1" class="my-radio ml-5">
              <div
                v-if="model.chapterAudioDTO.flag === 1"
                class="my-radio-choice"
              ></div>
            </div>
            <div class="font-2">按环节设置</div>
          </div>
        </div>
        <ChoiceAudio
          :audioVO="scenarioAudioBaseDTO"
          @putAudio="putAudio(0, $event)"
          :playMode="2"
          v-if="model.chapterAudioDTO.flag === 0"
          style="margin: 10px 0 0 115px"
        />
        <div v-if="model.chapterAudioDTO.flag === 1">
          <div
            v-for="item in scenarioAudioBaseDTOList"
            :key="item.index"
            class="flex"
          >
            <div class="flex mt-2" v-if="showAudioStage(item.index)">
              <div class="flex">
                <div class="label-pre mr-2"></div>
                <div class="label-text">{{ item.text }}：</div>
              </div>
              <ChoiceAudio
                :audioVO="item.scenarioAudioBaseDTO"
                @putAudio="putAudio(item.index, $event)"
                :playMode="2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="right-clue">
        <!-- -----------------------------------------线索栏 -->
        <div class="tab-title">线索</div>
        <div class="content">
          <div v-for="(items, index) in rightClueList" :key="index">
            <div v-if="items.children">
              <div style="color: #333333; margin-bottom: 8px">
                {{ items.labelName }}
              </div>
              <div
                :style="{ color: item.imgFlag && [1,2,3].includes(item.clueBelong) ? '#333333' : '#F394AC' }"
                class="item"
                v-for="(item, i) in items.children"
                :key="i"
              >
                <div>{{ i+1 &lt; 10 ? `0${i+1}`: i+1 }}</div>
                <div
                  class="item-name"
                  :style="{
                    marginLeft: '10px',
                    background:
                      item.clueBelong === 1
                        ? '#FEEBAE'
                        : item.clueBelong === 2
                        ? '#B4D4F4'
                        : item.clueBelong === 3
                        ? '#ABE3DE'
                        : '#fff',
                    borderRadius: '4px',
                    border: item.clueBelong === 4 ? '1px solid #C5C0E0' : '',
                    width: '130px',
                    height: '28px',
                    lineHeight: '28px',
                    padding: '0 5px',
                    position: 'relative',
                  }"
                >
                  <!-- 行动点 -->
                  <div class="item-expendPoint">
                    {{ item.expendPoint }}
                  </div>
                  {{ item.clueName }}
                </div>
                <div
                  style="
                    margin-left: 5px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <i
                    @click="editClue(item)"
                    class="el-icon-edit"
                    style="cursor: pointer; margin-right: 5px; color: #7f76bd"
                  ></i>
                  <el-popover
                    placement="right"
                    :title="`删除线索必须先解绑所有绑定关系！`"
                    width="40"
                    trigger="click"
                  >
                    <el-button
                      v-if="item.clueBelong === 4"
                      size="mini"
                      @click="deleteClue(item)"
                      type="danger"
                      >确认删除</el-button
                    >
                    <i
                      slot="reference"
                      class="el-icon-circle-close"
                      style="cursor: pointer; color: red"
                    ></i>
                  </el-popover>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 1px;
                  background: #eceafa;
                  margin: 31px 0 8px 0;
                "
              ></div>
            </div>
          </div>

          <div style="display: flex; justify-content: center">
            <el-button size="small" @click="addClue">添加</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 线索弹窗 -->
    <el-dialog
      :visible.sync="clueDialog"
      top="20px"
      width="60%"
      :close-on-click-modal="false"
    >
      <clue-dialog
        v-if="clueDialog"
        :chapterId="chapterId"
        :scenarioId="scenarioId"
        :chapterPersonList="chapterPersonList"
        :clueList="clueList"
        :allClueList="allClueList"
        :modelId="modelId"
        :labelList="labelList"
        :mapList="model.mapList"
        @afterSave="afterSave"
        @openClueDrawer="openClueDrawer"
      />
    </el-dialog>

    <!-- 地点线索弹窗 -->
    <el-dialog
      :visible.sync="placeDialog"
      top="20px"
      width="60%"
      :close-on-click-modal="false"
    >
      <place-dialog
        v-if="placeDialog"
        :chapterId="chapterId"
        :scenarioId="scenarioId"
        :chapterPersonList="chapterPersonList"
        :clueList="clueList"
        :allClueList="allClueList"
        :placeId="placeId"
        @afterSave="afterSave"
        @openClueDrawer="openClueDrawer"
      />
    </el-dialog>

    <!-- 人物线索弹窗 -->
    <el-dialog
      :visible.sync="peopleDialog"
      top="20px"
      width="60%"
      :close-on-click-modal="false"
    >
      <people-dialog
        v-if="peopleDialog"
        :chapterId="chapterId"
        :scenarioId="scenarioId"
        :peopleId="peopleId"
        :peopleClueId="peopleClueId"
        :chapterPersonList="chapterPersonList"
        :clueList="clueList"
        :allClueList="allClueList"
        @afterSave="afterSave"
        @openClueDrawer="openClueDrawer"
      />
    </el-dialog>

    <!-- 优化需求，线索抽屉 -->
    <el-drawer :visible.sync="clueDrawer" size="50%">
      <div style="padding: 0 10px">
        <clue-dialog
          v-if="clueDrawer && clueDrawerState === 3"
          :chapterId="chapterId"
          :scenarioId="scenarioId"
          :chapterPersonList="chapterPersonList"
          :clueList="clueList"
          :allClueList="allClueList"
          :modelId="clueDrawerModleId"
          :labelList="labelList"
          :mapList="model.mapList"
          @afterSave="afterSave"
        />
        <people-dialog
          v-if="clueDrawer && clueDrawerState === 2"
          :chapterId="chapterId"
          :scenarioId="scenarioId"
          :peopleId="clueDrawerModleId"
          :peopleClueId="clueDrawerPeopleId"
          :chapterPersonList="chapterPersonList"
          :clueList="clueList"
          :allClueList="allClueList"
          @afterSave="afterSave"
        />
        <place-dialog
          v-if="clueDrawer && clueDrawerState === 1"
          :chapterId="chapterId"
          :scenarioId="scenarioId"
          :chapterPersonList="chapterPersonList"
          :clueList="clueList"
          :allClueList="allClueList"
          :placeId="clueDrawerModleId"
          @afterSave="afterSave"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import clueDialog from "../components/clueDialog";
import placeDialog from "../components/placeDialog";
import peopleDialog from "../components/peopleDialog";
import ChoiceAudio from "@compts/common/ChoiceAudio.vue";
import { mapState, mapActions } from "vuex";
export default {
  props: ["scenarioId", "chapterId", "mList", "chapterNum"],
  components: {
    choiceImage,
    clueDialog,
    placeDialog,
    ChoiceAudio,
    peopleDialog,
  },
  data() {
    return {
      clueDialog: false, // 线索弹窗
      placeDialog: false, // 地点线索弹窗
      peopleDialog: false, // 人物线索弹窗
      mapLock: true, // 地图锁 默认开启
      chapterPersonList: [],
      clueList: [], // 幕线索列表
      rightClueList: [], //右侧线索栏
      // allClueList: [], // 所有幕线索列表
      modelId: "", // 待操作线索ID
      placeId: "", // 待操作地点ID
      peopleId: "", // 待操作人物ID
      personName: "",
      peopleClueId: "", // 待操作人物线索ID， 左下角

      clueDrawerState: 0, // 抽屉里显示的组件，1地点2人身3线索
      clueDrawerModleId: "",
      clueDrawerPeopleId: "",
      clueDrawer: false,

      // ------------地图相关
      imgHeight: "376px",
      imgHeightList: ["376px", "667px"], // 图片高度 调试用

      model: {
        actionPoints: "", // 行动点数
        personActionPoints: [], // 人物行动点数组列表
        aspectRatio: "", // 背景图宽高比
        pictureVo: {
          // 背景图
          id: "",
          picturePath: "",
        },
        showStatus: 1, // 1全显示，2仅名称，3仅图片
        showStyle: 1,
        mapList: [], // 地点列表
        scenarioChapterCountDownVo: {
          readTime: 30,
          selfIntroductionTime: 15,
          searchTime: 25,
          discussTime: 50,
          answerTime: 15,
          voteTime: 15,
        },
        chapterAudioDTO: {
          flag: 0,
          scenarioAudioBaseDTO: [],
        },
      },
      scenarioAudioBaseDTO: {},
      scenarioAudioBaseDTOList: [
        { text: "阅读阶段", index: 2, scenarioAudioBaseDTO: null },
        { text: "自我介绍", index: 3, scenarioAudioBaseDTO: null },
        { text: "搜证阶段", index: 4, scenarioAudioBaseDTO: null },
        { text: "答题阶段", index: 6, scenarioAudioBaseDTO: null },
        { text: "公投阶段", index: 7, scenarioAudioBaseDTO: null },
        { text: "结束阶段", index: 8, scenarioAudioBaseDTO: null },
      ],

      border1: require("../../../assets/frame_brown01s.png"),
      border2: require("../../../assets/frame_blue01s.png"),
      border3: require("../../../assets/frame_purple01s.png"),
      border4: require("../../../assets/frame_blue02s.png"),
      scenarioLabelList: [],
      refreshPoint: true,
      isshowmark: false,
      finshLoading: false,
      isAll: 0,
    };
  },
  computed: {
    ...mapState("scenario", ["allClueList", "labelList"]),
  },
  watch: {
    labelList() {
      this.getChapterClue();
    },
  },
  mounted() {
    window.addEventListener("mousewheel", this.middleScroll); // 鼠标滚动事件
    let keys = []; // 键盘快捷键唤起打开线索新增弹框
    document.onkeydown = (e) => {
      if (keys.indexOf(e.key) !== -1) return;
      keys[keys.length] = e.key;
      if (
        keys[0] === "Control" &&
        keys[1] === "Alt" &&
        (keys[2] === "z" || keys[2] === "Z")
      ) {
        this.addClue();
      }
    };
    document.onkeyup = () => {
      // 清空
      keys = [];
    };
  },
  destroyed() {
    window.removeEventListener("mousewheel", this.middleScroll);
    this.$bus.$off("updateChapterList", this.getChapterClue);
  },
  async created() {
    this.$bus.$on("updateChapterList", this.getChapterClue);
    // this.finshLoading = true
    try {
      await this.getChapterClue(); // 查询幕里的线索列表
      // await this.getScenarioClue(); // 查询剧本的所有线索列表
      await this.getPlaceList(); // 查询地点列表
      await this.getChapterPerson(); // 查询人物列表
    } finally {
      this.isshowmark = true;
      setTimeout(() => {
        // this.finshLoading = false;
      },500)
    }
  },
  methods: {
    ...mapActions("scenario", ["getScenarioLabel", "getAllClueList"]),
    showAudioStage(i) {
      const { chapterNum, mList } = this;
      const arr = [2, 4, 6];
      if (chapterNum === 1) {
        arr.push(3);
      } else if (chapterNum === mList.length) {
        arr.push(7, 8);
      }
      return arr.includes(i);
    },
    putAudio(index, v) {
      const value = v
        ? {
            playMode: v.playMode,
            materialAudioId: v.materialAudioId || v.id,
            url: v.audioUrl,
            audioTotalTime: v.audioTotalTime,
            way: index,
          }
        : null;

      index
        ? (this.scenarioAudioBaseDTOList.find(
            (item) => item.index === index
          ).scenarioAudioBaseDTO = value)
        : (this.scenarioAudioBaseDTO = value);
    },
    async getChapterPerson() {
      const res = await this.getRequest.getChapterPerson({
        scenarioId: this.scenarioId,
        chapterId: this.chapterId,
      });
      this.chapterPersonList = res.datas || [];
    },
    async getChapterClue() {
      let res = await this.getRequest.getChapterClue(this.chapterId);
      this.clueList = res.datas || [];
      if (!res.datas) {
        this.rightClueList = [];
        return;
      }
      const arr = JSON.parse(JSON.stringify(res.datas));
      const scenarioLabelList = JSON.parse(JSON.stringify(this.labelList));
      scenarioLabelList.push({
        labelId: "1",
        labelName: "待绑定线索",
        id: "0",
      });
      arr.forEach((c) => {
        const label = scenarioLabelList.find((item) => item.id === c.labelId);
        label &&
          (label.children ? label.children.push(c) : (label.children = [c]));
      });
      this.rightClueList = scenarioLabelList;
    },
    async getScenarioClue() {
      const res = await this.getRequest.getScenarioClue(this.scenarioId);
      this.allClueList = res.datas.sort((x, y) => {
        return x.chapterNum - y.chapterNum;
      });
    },
    rePersonName(id) {
      let re = "";
      this.chapterPersonList.forEach((res) => {
        if (res.personId === id) {
          re = res.personName;
        }
      });
      return re;
    },
    addClue() {
      this.modelId = "";
      this.clueDialog = true;
    },
    editClue(item) {
      this.modelId = item.id;
      this.clueDialog = true;
    },
    async deleteClue(item) {
      await this.getRequest.deleteClue(item.id);
      this.getChapterClue();
    },
    allEcpendPoint() {
      // 计算行动点数
      let point = 0;
      if (!this.clueList) return 0;
      this.clueList.forEach((element) => {
        point = point + element.expendPoint;
      });
      return point;
    },
    changeBackground(model) {
      // 修改大背景图
      let img = new Image();
      img.src = model.picturePath;
      img.onload = () => {
        this.model.aspectRatio = img.width / img.height;
        this.model.pictureVo = model;
        this.save();
      };
    },
    addPlace() {
      this.placeId = "";
      this.placeDialog = true;
    },
    editPlace(item) {
      this.placeId = item.clueId;
      this.placeDialog = true;
    },
    deletePlace(item, i) {
      // 删除地点
      this.model.mapList.splice(i, 1);
    },
    editPeople(item) {
      this.peopleId = item.personId;
      this.personName = item.personName;
      this.peopleClueId = item.id;
      this.peopleDialog = true;
    },
    middleScroll(e) {
      // 指定的某个地点进行缩放
      let target = e.target;
      let num = e.deltaY > 0 ? -1 : 1;
      if (target.className === "sonItem") {
        if (this.mapLock) return; // 地图锁限制
        document.getElementById("entryEvidence-card").style.overflowY =
          "hidden";
        this.changeWidth({}, Number(target.id), num);
      } else {
        document.getElementById("entryEvidence-card").style.overflowY = "auto";
      }
    },
    changeWidth(item, i, num) {
      // 宽度调整
      let mix = Number(this.model.mapList[i].width.split("%")[0]) + num;
      if (this.model.mapList[i].width < 1) return;
      this.model.mapList[i].width = mix + "%";
    },
    async afterSave(res, state, relevanceClueNum) {
      // res更新后的地点信息，state新增还是更新地点，relevanceClueNum地点最新的绑定线索数量
      await this.getChapterPerson();
      this.getPlaceList();
      this.getChapterClue();
      // this.getScenarioClue();
      this.getAllClueList(this.scenarioId);

      // 如果抽屉是开着的，则是抽屉的回调
      if (this.clueDrawer) {
        this.clueDrawer = false;
      } else {
        this.clueDialog = false;
        this.placeDialog = false;
        this.peopleDialog = false;
      }

      // 如果是新增了地点，则将值推入地点数组
      if (state === 1) {
        this.model.mapList.push({
          id: res.clueMapId,
          clueId: res.id,
          topLocal: "0",
          leftLocal: "0",
          height: "0",
          width: "18%",
          relevanceClueNum: 0,
          clueName: res.clueName,
          pictureVo: res.appearancePicture,
        });
      } else if (state === 2) {
        // 如果是更新地点信息，则改变必要数值
        this.model.mapList.forEach((val) => {
          if (val.clueId === res.id) {
            val.clueName = res.clueName;
            val.pictureVo = res.appearancePicture;
            val.relevanceClueNum = relevanceClueNum;
          }
        });
      }
    },
    async getPlaceList() {
      // 获取地图地点列表
      const res = await this.getRequest.getPlaceList(this.chapterId);
      const data = res.datas;
      if (data.chapterAudioDTO.flag === 0) {
        this.scenarioAudioBaseDTO =
          data.chapterAudioDTO.scenarioAudioBaseDTO[0] || null;
      } else {
        data.chapterAudioDTO.scenarioAudioBaseDTO.forEach((item) => {
          const scenarioAudioBase = this.scenarioAudioBaseDTOList.find(
            (v) => v.index === item.way
          );
          scenarioAudioBase && (scenarioAudioBase.scenarioAudioBaseDTO = item);
          // this.scenarioAudioBaseDTOList[

          //   item.way - 1
          // ].scenarioAudioBaseDTO = item;
        });
      }
      this.model = data;
    },
    copyMap(item) {
      // 复制地图
      this.$alert(
        "确定要复制第" +
          item.chapterNum +
          "幕的地图吗？复制地图后此幕原本地图数据将会清空",
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let arr = this.mList.map((v1) => {
            return v1.id;
          });
          let num = arr.indexOf(this.chapterId);
          this.getRequest
            .copyMap({
              copyChapterId: item.id,
              chapterId: this.chapterId,
              scenarioId: this.scenarioId,
              chapterNum: this.mList[num].chapterNum,
            })
            .then((res) => {
              if (res.respCode === 0) {
                this.getChapterClue(); // 查询幕里的线索列表
                this.getPlaceList(); // 查询地点列表
                this.getChapterPerson(); // 查询人物列表
              }
            });
        })
        .catch(() => {});
    },
    beforeSave() {},
    async save() {
      const model = JSON.parse(JSON.stringify(this.model));
      const scenarioAudioBaseDTO = !model.chapterAudioDTO.flag
        ? this.scenarioAudioBaseDTO
          ? [this.scenarioAudioBaseDTO]
          : []
        : this.scenarioAudioBaseDTOList.reduce(
            (t, c) => (
              c.scenarioAudioBaseDTO && t.push(c.scenarioAudioBaseDTO), t
            ),
            []
          );

      model.chapterAudioDTO.scenarioAudioBaseDTO = scenarioAudioBaseDTO.length
        ? scenarioAudioBaseDTO
        : null;
      const res = await this.getRequest.updatePlaceList(model);
      if (res.respCode === 0) {
        this.$message({
          message: "保存成功！",
          type: "success",
        });
      } else {
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
    },
    openClueDrawer(e, state, peopleClueId) {
      // 打开线索抽屉
      this.clueDrawerState = state;
      this.clueDrawerModleId = e;
      if (state === 2) {
        this.clueDrawerPeopleId = peopleClueId;
      }
      this.clueDrawer = true;
    },
  },
  directives: {
    // 自定义指令
    drag: (el, binding, vnode) => {
      let _this = vnode.context;
      let dragBox = el; //获取当前元素
      dragBox.onmousedown = (e) => {
        //算出鼠标相对元素的位置
        let disX = e.clientX - dragBox.offsetLeft;
        let disY = e.clientY - dragBox.offsetTop;
        document.onmousemove = (e) => {
          //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
          let left = e.clientX - disX;
          let top = e.clientY - disY;
          //移动当前元素
          let faWidth = document.getElementById("father").clientWidth;
          let faHeight = document.getElementById("father").clientHeight;
          let afterTop = Number(top / faHeight) * 100;
          let afterLeft = Number(left / faWidth) * 100;
          if (afterTop > 100) {
            afterTop = 100;
          }
          if (afterTop < 0) {
            afterTop = 0;
          }
          if (afterLeft > 100) {
            afterLeft = 100;
          }
          if (afterLeft < 0) {
            afterLeft = 0;
          }
          if (!_this.mapLock) {
            // 地图锁限制
            _this.model.mapList[dragBox.id].topLocal = afterTop + "%";
            _this.model.mapList[dragBox.id].leftLocal = afterLeft + "%";
          }
        };
        document.onmouseup = () => {
          //鼠标弹起来的时候不再移动
          document.onmousemove = null;
          //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动
          document.onmouseup = null;
        };
      };
    },
  },
};
</script>

<style lang='scss' scoped>
.evidenceAct {
  .tab-title {
    min-width: 80px;
    max-width: 100px;
    height: 24px;
    color: rgba(51, 51, 51, 1);
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    padding: 0 4px;
    line-height: 24px;
    text-align: center;
  }
  .el-divider {
    background: rgba(236, 234, 250, 1);
  }
  .row1 {
    display: flex;
    .left-map {
      width: calc(75% - 20px);
      padding-right: 10px;
      border-right: 1px solid #eceafa;
      .tips {
        margin-top: 10px;
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;
        .ball {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin-left: 10px;
        }
      }
      .time {
        margin-top: 10px;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        input {
          margin-left: 5px;
          width: 60px;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #7f76bd;
        }
      }
    }
    .right-clue {
      width: 25%;
      padding-left: 10px;
      .content {
        margin-top: 30px;
        padding: 0 10px;
        .item {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .item-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .item-expendPoint {
              position: absolute;
              right: -3px;
              top: -3px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
.label-pre {
  @include wh(4px, 24px);
  background: #eceafa;
  border-radius: 12px;
}
.label-text {
  width: 100px;
  line-height: 24px;
}
</style>

<style lang='scss' scoped>
// ----------------地图专属
.evidenceMap {
  position: relative;
  .map-mark {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.8);
  }
  .map-mark::after {
    content: "必须有一条线索才能录地图信息";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .rowTop {
    display: flex;
    justify-content: space-between;
    position: relative;
    .mapLock {
      position: absolute;
      right: 5px;
      top: 40px;
      z-index: 100;
    }
  }
  .content {
    margin-top: 10px;
    overflow-y: hidden;
    position: relative;
    .fatherDiv {
      position: relative;
      display: inline-block;
      .sonImg {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
      }
      .sonItem {
        width: 100%;
        position: relative;
        .item-text {
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          .img {
            position: absolute;
          }
          .item-text-content {
            position: absolute;
            text-align: center;
          }
        }
        .item-only-text {
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          .img {
            position: absolute;
          }
          .item-text-content {
            position: absolute;
            text-align: center;
          }
        }
        .sonItem-btn {
          position: absolute;
          visibility: hidden;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px;
          right: -54px;
          border-radius: 4px;
          border: 1px solid #c5c0e0;
          font-size: 16px;
        }
        .item-num {
          width: 15px;
          height: 15px;
          font-size: 12px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgb(254, 235, 174);
          position: absolute;
          top: -5px;
          left: -5px;
        }
      }
      .sonItem:hover .sonItem-btn {
        visibility: visible;
      }
      .sonItem:hover .sonItem-height {
        visibility: visible;
      }
      .sonItem:hover .sonItem-width {
        visibility: visible;
      }
    }
  }
}
</style>