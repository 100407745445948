<template>
  <!-- 地点弹窗 -->
  <div class="placeDialog" v-loading="backgroundLoading">
    <div class="placeDialog-title">
      <div class="placeDialog-title-tip" v-if="haveBind()">
        *未关联{{ haveBind() }}。
      </div>
      <div class="tab-title">
        {{ model.clueName ? model.clueName : "名称" }}
      </div>
    </div>

    <div style="height: 70vh; overflow-y: scroll">
      <div class="row">
        <div class="tab-title">名称</div>
        <el-input
          v-model="model.clueName"
          size="mini"
          style="width: 250px"
        ></el-input>
      </div>

      <div class="row" style="align-items: center">
        <div class="tab-title">图片</div>
        <choice-image
          @surePush="changePicture($event, 1)"
          :text="`内容配图`"
          :viewUrl="model.pictureVo.picturePath"
        />
        <i
          v-if="model.pictureVo.picturePath"
          @click="model.pictureVo = { id: 0, picturePath: '' }"
          class="el-icon-close"
          style="cursor: pointer; margin-left: 10px"
        ></i>
        <choice-image
          @surePush="changePicture($event, 2)"
          style="margin-left: 30px"
          :text="`外观图片`"
          :viewUrl="model.appearancePicture.picturePath"
        />
        <i
          v-if="model.appearancePicture.picturePath"
          @click="model.appearancePicture = { id: 0, picturePath: '' }"
          class="el-icon-close"
          style="cursor: pointer; margin-left: 10px"
        ></i>
      </div>

      <div class="row">
        <div class="tab-title">关联线索</div>
        <el-select
          style="margin-right: 10px"
          size="mini"
          v-model="model.relevanceClueList"
          multiple
          default-first-option
          @click-tag="clueDrawer"
          placeholder="请选择线索"
          filterable
        >
          <el-option
            v-for="option in clueList"
            :key="option.id"
            :disabled="option.clueBelong !== 4 || clueDisabled(option)"
            :label="option.clueName"
            :value="option.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="row">
        <div class="tab-title">描述</div>
        <el-input
          v-model="model.clueSynopsis"
          type="textarea"
          placeholder="地点描述..."
        ></el-input>
      </div>

      <div class="row">
        <div class="tab-title">搜证权限</div>
        <div>
          <div style="display: flex; align-items: center">
            <div class="my-radio">
              <div class="my-radio-choice"></div>
            </div>
            <el-select
              style="margin-right: 10px"
              size="mini"
              v-model="model.searchRolePersonIds"
              multiple
              default-first-option
              @click-tag="peopleDrawer"
              placeholder="请选择人物"
            >
              <el-button type="text" @click="selectAll" style="padding: 10px">
                全选
              </el-button>
              <el-option
                v-for="item in chapterPersonList"
                :key="item.personId"
                :label="item.personName"
                :value="item.personId"
              >
              </el-option>
            </el-select>

            <div
              class="my-radio"
              @click="model.isSearchClue = !model.isSearchClue"
            >
              <div class="my-radio-choice" v-if="model.isSearchClue"></div>
            </div>
            <el-select
              v-model="model.searchRoleGoodsIds"
              multiple
              default-first-option
              style="margin-right: 10px"
              size="mini"
              placeholder="请选择线索（可以公开"
              @click-tag="clueDrawer"
              @change="
                (e) => {
                  model.isSearchClue = e && e.length;
                }
              "
              filterable
            >
              <el-option
                v-for="item in allClueList"
                :key="item.id"
                :disabled="clueDisabled(item)"
                :label="item.clueName"
                :value="item.id"
              >
                <span style="float: left">{{ item.clueName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >第{{ item.chapterNum }}幕</span
                >
              </el-option>
            </el-select>
          </div>
          <div class="flex-ac mt-3">
            <div
              @click="
                () => {
                  model.haveQuestion = !model.haveQuestion;
                }
              "
              class="my-radio"
            >
              <div v-if="model.haveQuestion" class="my-radio-choice"></div>
            </div>
            <div>回答问题</div>
          </div>
          <div v-if="model.haveQuestion" class="flex-ac mt-2">
            <div style="width: 60px">形式:</div>
            <myswitch
              v-model="model.answerType"
              :leftValue="0"
              :rightValue="1"
              class="mr-3"
              leftText="任一"
              rightText="各自"
            />
            <div>角色答对才能搜此地点</div>
          </div>
          <div v-if="model.haveQuestion" class="flex-ac mt-2">
            <div style="width: 68px">问题:</div>
            <el-input
              v-model="model.topicContent"
              size="mini"
              placeholder="请输入问题"
            ></el-input>
          </div>
          <div v-if="model.haveQuestion" class="flex-ac mt-2">
            <div style="width: 68px">答案:</div>
            <el-input
              v-model="model.topicAnswer[0]"
              size="mini"
              placeholder="请输入答案"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="tab-title">音频设置</div>
        <ChoiceAudio
          :audioVO="model.scenarioAudioBaseDTO"
          @putAudio="putAudio"
          :playMode="1"
        />
      </div>
      <div class="flex-cc">
        <div style="width: 70%">
          <el-divider></el-divider>
        </div>
      </div>

      <!-- 事件列表 -->
      <div
        class="row"
        style="margin-bottom: 30px"
        v-for="(item, i) in model.locationChapterClueEventBoList"
        :key="i"
      >
        <div class="tab-title">触发事件{{ i + 1 }}</div>
        <div style="width: 600px">
          <div style="display: flex; align-items: center">
            <div class="my-radio">
              <div class="my-radio-choice"></div>
            </div>
            <el-select
              v-model="item.scenarioPersonId"
              size="mini"
              placeholder="请选择角色"
              @click-tag="peopleDrawer"
              clearable
            >
              <el-option label="任意一人" value="0"></el-option>
              <el-option
                v-for="item in chapterPersonList"
                :key="item.personId"
                :label="item.personName"
                :value="item.personId"
              >
              </el-option>
            </el-select>

            <!-- 线索 -->
            <div
              class="my-radio"
              style="margin-left: 10px"
              @click="item.haveClue = !item.haveClue"
            >
              <div class="my-radio-choice" v-if="item.haveClue"></div>
            </div>
            <el-select
              clearable
              v-model="item.needClueId"
              size="mini"
              placeholder="请选择线索（不能公开）"
              @click-tag="clueDrawer"
              @change="
                (e) => {
                  item.haveClue = !!+e;
                }
              "
              filterable
            >
              <el-option
                v-for="option in allClueList"
                :key="option.id"
                :disabled="clueDisabledItem(option, item)"
                :label="option.clueName"
                :value="option.id"
              >
                <span style="float: left">{{ option.clueName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >第{{ option.chapterNum }}幕</span
                >
              </el-option>
            </el-select>
          </div>

          <div style="display: flex; align-items: center; margin-top: 10px">
            <div class="my-radio" @click="item.isTouchOff = !item.isTouchOff">
              <div class="my-radio-choice" v-if="item.isTouchOff"></div>
            </div>
            <myswitch
              v-model="item.touchOffType"
              :leftText="`第`"
              :rightText="`前`"
            />
            <el-input-number
              v-model="item.touchOffNum"
              style="margin: 0 10px"
              size="mini"
              controls-position="right"
            ></el-input-number>
            次到达此地点时，
          </div>

          <div style="display: flex; align-items: center; margin-top: 10px">
            得到：
            <el-select
              clearable
              v-model="item.getClueId"
              style="margin: 0 10px"
              size="mini"
              placeholder="请选择线索（可以公开）"
              @click-tag="clueDrawer"
              filterable
            >
              <el-option
                v-for="option in clueList"
                :key="option.id"
                :disabled="
                  option.clueBelong !== 4 || clueDisabledItem(option, item)
                "
                :label="option.clueName"
                :value="option.id"
              >
              </el-option>
            </el-select>
            触发：
          </div>

          <div style="display: flex; align-items: flex-end; margin-top: 10px">
            <el-input
              v-model="item.eventContent"
              type="textarea"
              placeholder="请输入描述"
            ></el-input>
            <el-input-number
              v-model="item.score"
              style="margin: 0 10px; width: 120px"
              size="mini"
              controls-position="right"
              :min="0"
              :max="10"
            >
            </el-input-number>
            <div style="line-height: 28px">分</div>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="danger"
              circle
              icon="el-icon-close"
              plain
              @click="deleteEvent(item, i)"
            ></el-button>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-button @click="addEvent">添加事件</el-button>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button @click="save" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import ChoiceAudio from "@compts/common/ChoiceAudio.vue";
export default {
  components: {
    choiceImage,
    ChoiceAudio,
  },
  props: [
    "scenarioId",
    "chapterId",
    "clueList",
    "allClueList",
    "chapterPersonList",
    "placeId",
  ],
  data() {
    return {
      backgroundLoading: false,
      model: {
        scenarioId: this.scenarioId,
        chapterId: this.chapterId,
        clueName: "",
        haveQuestion: false,
        topicId: "",
        answerType: 0,
        topicAnswer: [""],
        topicContent: "",
        pictureVo: {
          id: 0,
          picturePath: "",
        },
        appearancePicture: {
          id: 0,
          picturePath: "",
        },
        relevanceClueList: [], // 关联线索ID
        clueSynopsis: "", // 描述
        searchRolePersonIds: [], // 搜证权限人物列表，允许触发的人，空则表示所有人
        searchRoleGoodsIds: [], // 搜证权限线索ID
        isSearchClue: false, // 是否需要选择线索
        locationChapterClueEventBoList: [
          {
            id: "",
            scenarioPersonId: "0", // 触发人Id
            haveClue: false, // 是否需要线索
            needClueId: "", //线索ID
            isTouchOff: false, // 是否需要第几次
            touchOffType: 1, // 1前2第
            touchOffNum: 1, // 几次
            getClueId: "", // 得到的线索ID
            eventContent: "",
            score: "",
            webId: 1,
          },
        ], // 触发事件列表
        scenarioAudioBaseDTO: null,
      },
    };
  },
  created() {
    if (this.placeId) {
      this.getPlace();
    }
  },
  methods: {
    haveBind() {
      const isHaveImg =
        this.model.pictureVo.picturePath ||
        this.model.appearancePicture.picturePath;
      const isHaveBind = this.model.relevanceClueList.length;
      const arr = [];
      !isHaveImg && arr.push("图片");
      !isHaveBind && arr.push("线索");
      return arr.join("、");
    },
    putAudio(v) {
      this.model.scenarioAudioBaseDTO = v
        ? {
            playMode: v.playMode,
            materialAudioId: v.materialAudioId || v.id,
            url: v.audioUrl,
            audioTotalTime: v.audioTotalTime,
          }
        : null;
    },
    dealParam(data) {
      data.scenarioAudioBaseDTO = data.scenarioAudioVO;
      this.$delete(data, "scenarioAudioVO");
      this.model = data;
    },
    async getPlace() {
      this.backgroundLoading = true;
      const res = await this.getRequest.getPlace(this.placeId);
      // this.dealParam(res.datas);
      res.datas.locationChapterClueEventBoList &&
        res.datas.locationChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : "";
        });

      res.datas.topicAnswer = res.datas.topicAnswer || [""];
      res.datas.answerType = res.datas.answerType || 0;
      this.model = res.datas;
      this.backgroundLoading = false;
    },
    selectAll() {
      // 搜证权限全选
      let peopleList = this.chapterPersonList.map((res) => {
        return res.personId;
      });
      if (peopleList.length === this.model.searchRolePersonIds.length) {
        this.model.searchRolePersonIds = [];
      } else {
        this.model.searchRolePersonIds = peopleList;
      }
    },
    changePicture(model, state) {
      // 图片选择回调方法
      if (state === 1) {
        this.model.pictureVo = model;
      } else {
        this.model.appearancePicture = model;
      }
    },
    addEvent() {
      let obj = this.$options.data().model.locationChapterClueEventBoList[0];
      obj.webId = this.model.locationChapterClueEventBoList.length + 1;
      this.model.locationChapterClueEventBoList.push(obj);
    },
    deleteEvent(item, i) {
      this.model.locationChapterClueEventBoList.splice(i, 1);
    },
    clueDisabled(option) {
      // 选项禁用 关联线索-->搜证权限中只能出现一次
      let list1 = this.model.relevanceClueList;
      let list2 = this.model.searchRoleGoodsIds;
      let list3 = this.model.locationChapterClueEventBoList.map((res) => {
        return res.needClueId;
      });
      let list4 = this.model.locationChapterClueEventBoList.map((res) => {
        return res.getClueId;
      });
      let bol1 = list1.indexOf(option.id) !== -1;
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = list3.indexOf(option.id) !== -1;
      let bol4 = list4.indexOf(option.id) !== -1;
      return bol1 || bol2 || bol3 || bol4;
    },
    clueDisabledItem(option, item) {
      // 触发事件（条件）-->触发事件（结果）中只能出现一次
      let list1 = this.model.relevanceClueList;
      let list2 = this.model.searchRoleGoodsIds;
      let bol1 = list1.indexOf(option.id) !== -1;
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = item.needClueId === option.id;
      let bol4 = item.getClueId === option.id;
      return bol1 || bol2 || bol3 || bol4;
    },
    async save() {
      let fun = "addPlace";
      if (this.model.id) {
        fun = "updatePlace";
        this.model.scenarioId = this.scenarioId;
      }
      this.model.locationChapterClueEventBoList &&
        this.model.locationChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : 0;
        });
      const res = await this.getRequest[fun](this.model);
      if (res.respCode === 0) {
        this.$message({
          message: "保存成功！",
          type: "success",
        });
        if (fun === "addPlace") {
          let model = this.model;
          model.id = res.datas.id;
          model.clueMapId = res.datas.clueMapId;
          this.$emit("afterSave", model, 1);
        } else {
          let relevanceClueNum = this.model.relevanceClueList.length;
          this.$emit("afterSave", this.model, 2, relevanceClueNum);
        }
      } else {
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
    },
    clueDrawer(e) {
      // 优化需求，线索框点击线索打开抽屉
      this.$emit("openClueDrawer", e, 3);
    },
    peopleDrawer(e) {
      // 遍历出peopleClueId
      if (e === "0") return this.$message.warning("不能选全部人");
      let peopleClueId = 0;
      this.chapterPersonList.forEach((res) => {
        if (res.personId === e) {
          peopleClueId = res.id;
        }
      });
      this.$emit("openClueDrawer", e, 2, peopleClueId);
    },
  },
};
</script>

<style lang='scss' scoped>
.row {
  display: flex;
  margin-top: 10px;
}
.tab-title {
  min-width: 80px;
  height: 28px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 28px;
  text-align: center;
  margin-right: 20px;
}
.my-radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #7f76bd;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .my-radio-choice {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7f76bd;
  }
}
.placeDialog {
  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    &-tip {
      position: absolute;
      left: 0;
      top: 32px;
      color: rgba(243, 148, 172, 1);
    }
  }
}
</style>