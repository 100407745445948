<template>
  <!-- 剧终投票和结算 -->
  <div class="evidenceScore">
    <!-- 剧终投票 -->
    <div class="tab-title">剧终投票</div>
    <div class="row" style="margin-top: 30px">
      <div style="margin-right: 60px">题目：</div>
      <div>
        <el-input size="mini" style="width: 400px" v-model="model.scenarioEndVoteTopicBo.topicContent"></el-input>
      </div>
    </div>

    <div class="row" style="margin-top: 30px">
      <div style="margin-right: 60px">答案：</div>
      <div class="row" style="flex-wrap: wrap; width: calc(100% - 130px);">
        <div class="row" style="align-items: center; margin:0 10px 10px 0" v-for="(option, oi) in model.scenarioEndVoteTopicBo.scenarioEndOptionVoList" :key="oi">
          <div style="margin-right: 10px; display:flex; align-items:center">
            {{option.optionNumber}}
          </div>
          <div>
            <el-select
              v-model="option.optionContent"
              size="mini"
              style="width: 120px"
              allow-create
              filterable
              placeholder="自定义"
              @change="selectChange($event, oi)"
            >
              <el-option
                :disabled="optionDisabled(choice.personName)"
                v-for="(choice, ci) in peopleList"
                :key="ci"
                :label="choice.personName"
                :value="choice.personName"
              ></el-option>
            </el-select>
            <i style="margin-left: 5px; cursor: pointer" class="el-icon-circle-close" type="primary" @click="deleteOption(oi)"></i>
          </div>
        </div>
        <!-- 新增答案 -->
        <el-button type="text" style="height: 28px; margin:0 0 0 10px" icon="el-icon-circle-plus-outline" size="mini" @click="addOption">新增答案</el-button>
      </div>
    </div>

    <!-- 得分 -->
    <div class="row" style="margin-top: 30px">
      <div style="margin-right: 60px">得分：</div>
      <div>
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="changeType(3)"
            v-if="model.scenarioEndVoteTopicBo.type === 4"
            >复杂结算</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="changeType(4)"
            v-if="model.scenarioEndVoteTopicBo.type === 3"
          >简单结算</el-button>
          <span style="color: #F394AC; margin-left: 10px">注：切换结算方式将重置剧终投票信息</span>
        </div>
        <div v-if="model.scenarioEndVoteTopicBo.type === 4">
          <!-- 第一行横坐标 -->
          <div class="row" style="margin-top: 10px">
            <div class="tab tab-top-left" style="position: relative; font-size: 14px">
              <span style="position: absolute; right: 4px; top: 0">投票人</span>
              <span style="position: absolute; left: 4px; bottom: 0">被投人</span>
            </div>
            <div class="tab bg1" v-for="(xAis, xi) in peopleList" :key="xi">
              {{ xAis.personName }}
            </div>
          </div>

          <div class="row" v-for="(people, pi) in model.scenarioEndVoteTopicBo.scenarioEndOptionVoList" :key="pi">
            <div class="tab bg2">{{people.optionContent}}</div>
            <div
              class="tab"
              v-for="(score, si) in people.scenarioEndVoteVoList"
              :key="si"
            >
              <input class="zdy-input bg2" type="number" v-model="score.voteScore" style="margin-right:5px">
              <input class="zdy-input bg1" type="number" v-model="score.testifyScore">
            </div>
          </div>
        </div>

        <div v-if="model.scenarioEndVoteTopicBo.type === 3">
          <div style="margin-top: 10px">
            凶手 被投票
            <input
              class="zdy-input"
              v-model="model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList[0].testifyScore"
              style="margin:0 5px"
            > 分/票，其他所有人物
            <input
              class="zdy-input"
              style="margin:0 5px"
              v-model="model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList[0].voteScore"
            >分
          </div>
          <div style="margin-top: 10px">
            凶手没被投票
            <input
              class="zdy-input"
              v-model="model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList[1].testifyScore"
              style="margin:0 5px"
            > 分/票，其他所有人物
            <input
              class="zdy-input"
              style="margin:0 5px"
              v-model="model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList[1].voteScore"
            >分
          </div>
        </div>
        <div v-if="model.scenarioEndVoteTopicBo.type === 4" style="margin-top: 10px; font-size: 14px; color: #999999">框内分值：左为被投票人得分，右为投票人得分</div>
        <div v-if="model.scenarioEndVoteTopicBo.type === 3" style="margin-top: 10px; font-size: 14px; color: #999999">凶手的投票将不起任何作用。</div>
      </div>
    </div>
    <!-- 凶手 -->
    <div class="row"  style="margin-top: 30px; align-items: center;">
      <div style="margin-right: 60px">凶手：</div>
      <!-- 简单模式单选 -->
      <el-select
        v-if="model.scenarioEndVoteTopicBo.type === 3"
        v-model="model.scenarioEndVoteTopicBo.topicAnswers[0]"
        size="mini"
        @change="killerChange"
      >
        <el-option
          v-for="(killer, ki) in model.scenarioEndVoteTopicBo.scenarioEndOptionVoList"
          :key="ki"
          :label="killer.optionContent"
          :value="killer.optionNumber"
        ></el-option>
      </el-select>
      <!-- 复杂模式多选 -->
      <el-select
        v-if="model.scenarioEndVoteTopicBo.type === 4"
        v-model="model.scenarioEndVoteTopicBo.topicAnswers"
        multiple
        size="mini"
      >
        <el-option
          v-for="(killer, ki) in model.scenarioEndVoteTopicBo.scenarioEndOptionVoList"
          :key="ki"
          :label="killer.optionContent"
          :value="killer.optionNumber"
        ></el-option>
      </el-select>
      <span style="font-size:14px;color:#999">（简单结算中，凶手是算分依据，很重要。复杂结算中，凶手是哪一个角色不重要，将通过设置得分体现。）</span>
    </div>
    
    <div style="margin:50px 0">
      <el-divider/>
    </div>

    <!-- 剧终投票 -->
    <div class="tab-title">剧终结算</div>
    <div style="color: #999999; font-size: 14px">人物多选并“有”是“或”关系，要达到“和”关系必须设置多个项目。（多选时所有人同时得分）</div>
    <div class="row" style="margin-top: 30px">
      <div style="margin-right: 60px">项目：</div>
      <div>
        <div v-for="(jzjs, ji) in model.scenarioEndCalculateVoList" :key="ji" class="row" style="margin-bottom: 10px; align-items: center">
          <el-select
            v-model="jzjs.scenarioPerson"
            size="mini"
            multiple
            placeholder="请选择角色"
            filterable
          >
            <el-option
              v-for="(people, pi) in peopleList"
              :key="pi"
              :label="people.personName"
              :value="people.id"
            ></el-option>
          </el-select>
          <myswitch style="margin: 0 10px" v-model="jzjs.isOwn" leftText="有" rightText="无"/>
          <el-select
            style="margin-right: 10px"
            size="mini"
            v-model="jzjs.needClueId"
            placeholder="请选择线索"
            filterable
          >
              <el-option
                v-for="option in allClueList"
                :key="option.id"
                :label="option.clueName"
                :value="option.id">
                  <span style="float: left">{{ option.clueName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">第{{ option.chapterNum }}幕</span>
              </el-option>
          </el-select>
          <el-input v-model="jzjs.score" style="width: 120px; margin-left: 10px;" size="mini">
            <template slot="append">
              分
            </template>
          </el-input>
          <i @click="deleteItem(ji)" class="el-icon-circle-close" style="margin-left:10px; cursor: pointer"></i>
        </div>

        <!-- 新增 -->
        <el-button type="text" icon="el-icon-circle-plus-outline" @click="addItem">新增项目</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      scenarioId: localStorage.getItem('scenarioId'),
      peopleList: [], // 剧本人物列表,表格横坐标
      allClueList: [], // 所有线索列表
      model: {
        scenarioEndVoteTopicBo: {
          topicType: 1,
          topicContent: '', // 题目
          scenarioId: localStorage.getItem('scenarioId'), // 剧本ID
          topicAnswers: [], // 凶手
          scenarioEndOptionVoList: [], // 答案
          type: 3, // 3简单模式，4复杂模式
          answerPersonId: '0', // 吕英杰个需
          simpleScenarioEndVoteList: [ // 简单模式的模板
            {
              scenarioId: localStorage.getItem('scenarioId'),
              votePerson: 0,
              testifyPerson: 0, // 凶手ID
              testifyScore: 0,
              voteScore: 0,
              pitchOn: 1
            },
            {
              scenarioId: localStorage.getItem('scenarioId'),
              votePerson: 0,
              testifyPerson: 0, // 凶手ID
              testifyScore: 0,
              voteScore: 0,
              pitchOn: 2
            }
          ],
        },
        scenarioEndCalculateVoList: [
          {
            scenarioId: localStorage.getItem('scenarioId'),
            scenarioPerson: [],
            isOwn: 1,
            needClueId: '',
            score: 5
          }
        ],
      }
    }
  },
  created () {
    this.getEndingScore()
    this.getPersonList()
    this.getScenarioClue()
  },
  methods: {
    async getEndingScore () {
      const res = await this.getRequest.getEndingScore(this.scenarioId)
      if (res.respCode === 0) {
        this.model = res.datas
      } else {
        this.addOption()
      }
    },
    selectChange (name, oi) {
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList[oi].optionContent = name
      // 根据名字找出唯一ID, 赋值给所有子项被投人字段
      let id = 0
      this.peopleList.forEach(val => {
        if (val.personName === name) {
          id = val.id
        }
      })
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList[oi].scenarioEndVoteVoList.forEach(val => {
        val.testifyPerson = id
      })
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.forEach(res => {
        if (res.optionContent === name) {
          res.personId = id
        }
      })
    },
    changeType (state) {
      this.$confirm('切换结算方式将重置剧终投票信息，是否继续？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 更改结算模式
        this.model.scenarioEndVoteTopicBo.type = state
        this.model.scenarioEndVoteTopicBo.topicAnswers = []
        // 还原初始复杂模式数组
        this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList = this.$options.data().model.scenarioEndVoteTopicBo.scenarioEndOptionVoList
        this.addOption()
        if (state === 3) {
          this.model.scenarioEndVoteTopicBo.topicType = 1
          // 还原简单模式的数组
          this.model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList = this.$options.data().model.scenarioEndVoteTopicBo.simpleScenarioEndVoteList
        } else if (state === 4) {
          this.model.scenarioEndVoteTopicBo.topicType = 2
        }
      }).catch(() => {

      })
    },
    optionDisabled (name) {
      let list = this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.map(res => {
        return res.optionContent
      })
      return list.some(res => {
        if (res === name) {
          return true
        }
      })
    },
    addOption () {
      let obj = {
        id: '',
        optionNumber: 'A', // 选项序号
        optionContent: '(自定义)', // 选项内容
        sortNo: this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.length + 1,
        scenarioEndVoteVoList: [], // 表格存储结构
        personId: 0
      }
      obj.optionNumber = String.fromCharCode(Number(65 + this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.length))
      // 生成表格数据的结构
      let list = this.peopleList.map(res => {
        return {
          scenarioId: this.scenarioId,
          votePerson: res.id, // 投票人
          testifyPerson: 0, // 被投人
          voteScore: 0,
          testifyScore: 0,
          pitchOn: 1
        }
      })
      obj.scenarioEndVoteVoList = list
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.push(obj)
    },
    deleteOption (oi) {
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.splice(oi, 1)
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.forEach((element,i) => {
        element.optionNumber = String.fromCharCode(Number(65 + i))
        element.sortNo = i + 1
      })
      // 删除选项时要把凶手字段也删除
      this.model.scenarioEndVoteTopicBo.topicAnswers = []
    },
    addItem () {
      // 新增项目
      let obj = this.$options.data().model.scenarioEndCalculateVoList[0]
      this.model.scenarioEndCalculateVoList.push(obj)
    },
    deleteItem (i) {
      this.model.scenarioEndCalculateVoList.splice(i, 1)
    },
    async getPersonList () {
      const res = await this.getRequest.getPersonList(this.scenarioId)
      this.peopleList = res.datas
    },
    async getScenarioClue () {
      const res = await this.getRequest.getScenarioClue(this.scenarioId)
      this.allClueList = res.datas.sort((x,y)=>{return x.chapterNum - y.chapterNum})
    },
    killerChange (e) {
      let content = ''
      this.model.scenarioEndVoteTopicBo.scenarioEndOptionVoList.forEach(res => {
        if (res.optionNumber === e) {
          content = res.optionContent
        }
      })
      let personId = '0'
      this.peopleList.forEach(res => {
        if (res.personName === content) {
          personId = res.id
        }
      })
      this.model.scenarioEndVoteTopicBo.answerPersonId = personId
    },
    async save () {
      let fun = 'addEndingScore'
      if (this.model.scenarioEndVoteTopicBo.id) {
        fun = 'updateEndingScore'
      }
      const res = await this.getRequest[fun](this.model)
      if (res.respCode === 0) {
        if (fun === 'addEndingScore') {
          this.$message({
            message: '保存成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '更新成功！',
            type: 'success'
          })
        }
        this.model = res.datas
      } else {
        this.$message({
          message: res.respMsg,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .el-divider {
    background: rgba(236, 234, 250, 1);
  }
  .tab-title {
    width: 80px;
    height: 24px;
    color: rgba(51,51,51,1);
    background: rgba(236,234,250,1);
    border-radius: 4px;
    padding: 0 4px;
    line-height: 24px;
    text-align: center;
    margin-right: 30px;
  }
  .row {
    display: flex;
  }
  .eb-text-area {
    margin-top: 10px;
    overflow-y: scroll;
    border-radius: 4px;
    border: 1px solid rgba(197,192,224,1);
    height: 250px;
    padding: 0 10px;
  }
  .tab-top-left {
    background: linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 1px),
      rgba(197,192,224,1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 1px),
      rgba(0, 0, 0, 0) 100%
    )
  }
  .tab {
    width: 110px;
    height: 36px;
    text-align:center;
    border: 1px solid rgba(199,194,225,1);
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: -1px;
    margin-top: -1px;
  }
  .zdy-input {
    border: 1px solid rgba(197,192,224,1);
    border-radius: 4px;
    height: 24px;
    width: 30px;
    text-align: center;
  }
  .bg1 {
    background: rgb(180, 212, 244, 0.5);
  }
  .bg2 {
    background: rgb(171, 227, 222, 0.5);
  }
  // 隐藏所有INput框的上下标按钮
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>