<template>
  <!-- 线索标签弹窗 -->
  <div>
    <!-- 优化需求-建立标签和所有线索的分类列表 -->
    <div class="label-clue dialog" v-if="flag">
      <el-card
        style="margin: 10px 10px 10px 0"
        v-for="(item, i) in labelClueMap"
        :key="i"
      >
        <!-- 标签名 -->
        <div style="color: #7f76bd; display: flex; align-items: center">
          <div style="margin-right: 10px">
            {{ item.labelName }}({{ item.len }})
          </div>

          <i
            v-if="item.id !== '0'"
            @click="editLabel(item, i)"
            class="el-icon-edit-outline"
            style="cursor: pointer; margin: 0 5px"
          ></i>

          <i
            @click="deleteLabel(item, i)"
            class="el-icon-circle-close"
            style="cursor: pointer; margin: 0 5px"
          ></i>
        </div>

        <div
          :id="`labelCard${i}`"
          :i="i"
          :labelid="item.id"
          style="margin-top: 10px; min-height: 25px"
        >
          <el-tag
            v-for="(clue, ci) in item.linkClue"
            :key="ci"
            :clueid="clue.id"
            size="mini"
            style="margin-right: 10px; margin-bottom: 5px"
            type="info"
          >
            <span @click="clickClue(clue)" style="cursor: pointer">
              {{ clue.clueName }}
            </span>
            <el-popover
              v-model="clue.showPopover"
              placement="right"
              width="200"
              trigger="click"
            >
              <div>
                移动至：
                <el-link
                  style="
                    color: #7f76bd;
                    margin-right: 14px;
                    margin-bottom: 10px;
                  "
                  v-for="(move, mi) in labelClueMap"
                  :key="mi"
                  @click="updateClueLabel(item, i, clue, ci, move, mi)"
                >
                  {{ move.labelName }}
                </el-link>
              </div>
              <i
                slot="reference"
                class="el-icon-rank"
                style="cursor: pointer; margin: 0 5px"
              ></i>
            </el-popover>
          </el-tag>
        </div>
      </el-card>
    </div>

    <el-input
      size="small"
      v-model="labelName"
      placeholder="标签名"
      style="width: 200px"
    ></el-input>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin-left: 10px"
      size="small"
      @click="addLabel"
    >
      添加标签
    </el-button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Sortable from "sortablejs";
export default {
  props: ["scenarioId", "chapterId"],
  data() {
    return {
      labelName: "",
      dictLabel: "",
      flag: 1,
      labelClueMap: [],
    };
  },
  computed: {
    ...mapState("scenario", ["allClueList", "labelList"]),
  },
  created() {
    this.getScenarioClue(); // 获取所有线索列表
    // this.getLabel();
  },
  methods: {
    ...mapActions("scenario", ["getScenarioLabel", "getAllClueList"]),
    async getLabel() {
      const res = await this.getRequest.getScenarioLabel({
        scenarioId: this.scenarioId,
      });
      if (res.respCode === 0) {
        this.labelList = res.datas;
        this.getScenarioClue2(); // 获取所有线索列表
      }
    },
    async getScenarioClue2() {
      const res = await this.getRequest.getScenarioClue(this.scenarioId);
      this.allClueList = res.datas.sort((x, y) => {
        return x.chapterNum - y.chapterNum;
      });

      let map = [];
      // 处理标签
      this.labelList.forEach((val1) => {
        val1.linkClue = [];
        map.push(val1);
      });
      map.push({ labelName: "未关联线索", id: "0", linkClue: [] });
      // 处理线索数据
      this.allClueList.forEach((val2) => {
        pushWhere(val2);
      });
      function pushWhere(item) {
        // 把线索放到哪个标签下面
        map.forEach((val3) => {
          if (val3.id == item.labelId) {
            val3.linkClue.push(item);
          }
        });
      }
      map.forEach((item) => (item.len = item.linkClue.length));

      this.labelClueMap = map;
    },
    async getScenarioClue() {
      // let allClueList = JSON.parse(JSON.stringify(this.allClueList));
      let map = [];
      // 处理标签
      this.labelList.forEach((val1) => {
        val1.linkClue = [];
        map.push(val1);
      });
      map.push({ labelName: "未关联线索", id: "0", linkClue: [] });
      // 处理线索数据
      this.allClueList.forEach((val2) => {
        pushWhere(val2);
      });
      function pushWhere(item) {
        // 把线索放到哪个标签下面 run
        map.forEach((val3) => {
          if (val3.id == item.labelId) {
            val3.linkClue.push(item);
          }
        });
      }
      map.forEach((item) => (item.len = item.linkClue.length));
      setTimeout(() => {
        this.labelClueMap = map;
        this.initSort();
      }, 1);
    },
    initSort() {
      this.$nextTick(() => {
        const arr = [];
        for (let index = 0; index < this.labelClueMap.length; index++) {
          const ref = document.getElementById(`labelCard${index}`);
          arr.push(ref);
        }
        arr.forEach((ref) => {
          new Sortable(ref, {
            group: "shared", // set both lists to same group
            animation: 150,
            // 元素从一个列表拖拽到另一个列表
            onAdd: async ({ clone, to, from }) => {
              const clueid = clone.attributes.clueid.value;
              const oldi = from.attributes.i.value;
              const clueLabelId = to.attributes.labelid.value;
              const newi = to.attributes.i.value;
              await this.getRequest.updateClueLabel({
                id: clueid,
                clueId: clueid,
                clueLabelId,
              });

              const labelItem = this.labelClueMap[newi];
              const oldItem = this.labelClueMap[oldi];
              oldItem.len = oldItem.len - 1;
              labelItem.len = labelItem.len + 1;
              this.flag = this.flag++
              this.getAllClueList(this.scenarioId)
              this.$bus.$emit("updateChapterList");
            },
          });
        });
      });
    },
    async editLabel(label, i) {
      // 点击标签待操作
      this.$prompt(label.labelName, "修改标签名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async (val) => {
          const res = await this.getRequest.updateScenarioLabel({
            id: label.id,
            labelName: val.value,
            scenarioId: this.scenarioId,
            chapterId: this.chapterId,
          });
          if (res.respCode === 0) {
            this.labelClueMap[i].labelName = val.value;
            this.getScenarioLabel(this.scenarioId);
          }
        })
        .catch(() => {});
    },
    async deleteLabel(label, i) {
      this.$confirm(`确认要删除-${label.labelName}吗？`, "删除标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.getRequest.deleteScenarioLabel(label.id);
          if (res.respCode === 0) {
            this.labelClueMap.splice(i, 1);
            // this.$store.dispatch("getScenarioLabel", this.scenarioId);
            this.getScenarioLabel(this.scenarioId);
          }
        })
        .catch(() => {});
    },
    async addLabel() {
      if (!this.labelName) {
        return this.$message.warning("请填写标签名");
      }
      const res = await this.getRequest.addScenarioLabel({
        scenarioId: this.scenarioId,
        chapterId: this.chapterId,
        labelName: this.labelName,
      });
      if (res.respCode === 0) {
        this.labelName = "";
        await this.getScenarioLabel(this.scenarioId);
        this.getScenarioClue();
      }
    },
    async updateClueLabel(item, i, clue, ci, move, mi) {
      // 线索本身，线索位置，移动到哪个标签，移动到的标签的所在位置
      // 更新线索标签信息
      const res = await this.getRequest.updateClueLabel({
        id: clue.id,
        clueId: clue.id,
        clueLabelId: move.id,
      });
      if (res.respCode === 0) {
        // 删除掉原本所在的位置， 在新的线索中push进去
        this.labelClueMap[i].linkClue[ci].showPopover = false;
        this.labelClueMap[i].len = this.labelClueMap[i].len - 1;
        this.labelClueMap[mi].len = this.labelClueMap[mi].len + 1;
        this.labelClueMap[i].linkClue.splice(ci, 1);
        this.labelClueMap[mi].linkClue.push(clue);
        this.flag++;

        this.$bus.$emit("updateChapterList");
      }
    },
    clickClue(clue) {
      this.$emit("clickClue", clue.id);
    },
  },
};
</script>

<style lang='scss' scoped>
.label-clue {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
</style>