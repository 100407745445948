<template>
  <!-- 人物弹窗 -->
  <div class="peopleDialog" v-loading="backgroundLoading">
    <div style="display: flex; justify-content: center; margin-bottom: 30px">
      <div class="tab-title">{{ personName() }}</div>
    </div>

    <div style="height: 70vh; overflow-y: scroll">
      <div class="row">
        <div class="tab-title">关联线索</div>
        <el-select
          filterable
          style="margin-right: 10px"
          size="mini"
          v-model="model.relevanceClueList"
          multiple
          default-first-option
          autocomplete
          placeholder="请选择线索"
          @click-tag="clueDrawer"
        >
          <el-option
            v-for="option in clueList"
            :key="option.id"
            :disabled="clueItemDisabled(option)"
            :label="option.clueName"
            :value="option.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="row">
        <div class="tab-title">描述</div>
        <el-input
          v-model="model.clueSynopsis"
          type="textarea"
          placeholder="地点描述..."
        ></el-input>
      </div>

      <div class="row" style="align-items: center">
        <div class="tab-title">搜证权限</div>
        <div style="display: flex; align-items: center">
          <div class="my-radio">
            <div class="my-radio-choice"></div>
          </div>
          <el-select
            style="margin-right: 10px"
            size="mini"
            v-model="model.searchRolePersonIds"
            multiple
            default-first-option
            @click-tag="peopleDrawer"
            placeholder="请选择人物"
          >
            <el-button type="text" @click="selectAll" style="padding: 10px">
              全选
            </el-button>
            <el-option
              v-for="item in chapterPersonList"
              :key="item.personId"
              :label="item.personName"
              :value="item.personId"
            >
            </el-option>
          </el-select>

          <div
            class="my-radio"
            @click="model.isSearchClue = !model.isSearchClue"
          >
            <div class="my-radio-choice" v-if="model.isSearchClue"></div>
          </div>
          <el-select
            v-model="model.searchRoleGoodsIds"
            multiple
            default-first-option
            style="margin-right: 10px"
            size="mini"
            @change="
              (e) => {
                model.isSearchClue = e && e.length
              }
            "
            filterable
            placeholder="请选择线索（可以公开"
          >
            <el-option
              v-for="option in allClueList"
              :key="option.id"
              :disabled="clueDisabled(option)"
              :label="option.clueName"
              :value="option.id"
            >
              <span style="float: left">{{ option.clueName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px"
                >第{{ option.chapterNum }}幕</span
              >
            </el-option>
          </el-select>
        </div>
      </div>

      <!-- 事件列表 -->
      <div
        class="row"
        style="margin-bottom: 30px"
        v-for="(item, i) in model.personChapterClueEventBoList"
        :key="i"
      >
        <div class="tab-title">触发事件{{ i + 1 }}</div>
        <div style="width: 600px">
          <div style="display: flex; align-items: center">
            <div class="my-radio">
              <div class="my-radio-choice"></div>
            </div>
            <el-select
              style="margin-right: 10px"
              size="mini"
              clearable
              v-model="item.scenarioPersonId"
              @click-tag="peopleDrawer"
              placeholder="请选择人物"
            >
              <el-option :label="`任意一人`" :value="`0`"> </el-option>
              <el-option
                v-for="option in chapterPersonList"
                :key="option.personId"
                :label="option.personName"
                :value="option.personId"
              >
              </el-option>
            </el-select>

            <div style="display: flex; align-items: center">
              <div class="my-radio" @click="item.haveClue = !item.haveClue">
                <div class="my-radio-choice" v-if="item.haveClue"></div>
              </div>
              <el-select
                style="margin-right: 10px"
                size="mini"
                v-model="item.needClueId"
                clearable
                filterable
                @click-tag="clueDrawer"
                @change="
                  (e) => {
                    item.haveClue = +e;
                  }
                "
                placeholder="请选择线索（不能公开"
              >
                <el-option
                  v-for="option in allClueList"
                  :disabled="clueDisabledItem(option, item)"
                  :key="option.id"
                  :label="option.clueName"
                  :value="option.id"
                >
                  <span style="float: left">{{ option.clueName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                    >第{{ option.chapterNum }}幕</span
                  >
                </el-option>
              </el-select>
              <span> 搜其身时 </span>
            </div>
          </div>

          <div style="margin-top: 10px">
            得到：
            <el-select
              v-model="item.getClueId"
              style="margin-right: 10px"
              size="mini"
              placeholder="请选择线索（可以公开）"
              clearable
              filterable
              @click-tag="clueDrawer"
            >
              <el-option
                v-for="option in clueList"
                :key="option.id"
                :disabled="
                  option.clueBelong !== 4 || clueDisabledItem(option, item)
                "
                :label="option.clueName"
                :value="option.id"
              >
              </el-option>
            </el-select>
            触发
          </div>

          <div style="margin-top: 10px; display: flex; align-items: flex-end">
            <el-input
              v-model="item.eventContent"
              type="textarea"
              placeholder="请输入事件"
            ></el-input>
            <el-input-number
              v-model="item.score"
              style="margin: 0 10px; width: 120px"
              size="mini"
              controls-position="right"
              :min="0"
              :max="10"
            >
            </el-input-number>
            <div style="line-height: 28px">分</div>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="danger"
              circle
              icon="el-icon-close"
              @click="deleteEvent(item, i)"
              plain
            ></el-button>
          </div>
        </div>
      </div>

      <!-- 添加事件 -->
      <div style="display: flex; justify-content: center; margin-top: 30px">
        <el-button icon="el-icon-plus" @click="addEvent">添加事件</el-button>
      </div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button @click="save" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "chapterId",
    "scenarioId",
    "peopleId",
    "peopleClueId",
    "chapterPersonList",
    "clueList",
    "allClueList",
  ],
  data() {
    return {
      backgroundLoading: false,
      model: {
        scenarioId: this.scenarioId,
        chapterId: this.chapterId,
        scenarioPerson: this.peopleId,
        clueSynopsis: "",
        relevanceClueList: [],
        isSearchClue: false,
        searchRolePersonIds: [],
        searchRoleGoodsIds: [],
        personChapterClueEventBoList: [
          {
            id: "",
            scenarioPersonId: "0",
            needClueId: "",
            clueId: "",
            getClueId: "",
            haveClue: false, // 是否需要选择线索
            eventContent: "",
            score: 5,
            webId: 1,
          },
        ],
        combinationEventsList: [],
      },
    };
  },
  created() {
    if (this.peopleClueId) {
      this.getPersonClue();
    } else {
      // 优化需求，打开新线索窗口，默认搜证权限选中所有人，默认0条触发事件
      let list = this.chapterPersonList.map((res) => {
        return res.personId;
      });
      this.model.searchRolePersonIds = list;
      this.model.personChapterClueEventBoList = [];
    }
  },
  methods: {
    clueItemDisabled(option) {
      const flag =
        this.model.personChapterClueEventBoList &&
        this.model.personChapterClueEventBoList.some((item) => {
          return item.getClueId === option.id || item.needClueId === option.id;
        });
      return (
        option.clueBelong !== 4 ||
        this.model.searchRoleGoodsIds.includes(option.id) ||
        flag
      );
    },
    // 根据peopleId和chapterPersonList遍历出名字
    personName() {
      let re = "";
      this.chapterPersonList.forEach((res) => {
        if (res.personId == this.peopleId) {
          re = res.personName;
        }
      });
      return re;
    },
    async getPersonClue() {
      this.backgroundLoading = true;
      const res = await this.getRequest.getPersonClue(this.peopleClueId);
      res.datas.personChapterClueEventBoList &&
        res.datas.personChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : "";
        });
      this.model = res.datas;
      this.backgroundLoading = false;
    },
    addEvent() {
      // 添加触发事件
      let obj = this.$options.data().model.personChapterClueEventBoList[0];
      obj.webId = this.model.personChapterClueEventBoList.length + 1;
      this.model.personChapterClueEventBoList.push(obj);
    },
    deleteEvent(item, i) {
      // 删除触发事件， 同时删除所有事件组中存在的序号, webId重新排序
      this.model.personChapterClueEventBoList.splice(i, 1);
      this.model.personChapterClueEventBoList.forEach((ev, ei) => {
        ev.webId = ei + 1;
      });
      this.model.combinationEventsList.forEach((ele) => {
        let num = 0; // 被切割序号的值
        ele.bindingEvent.forEach((val, vi) => {
          if (val === Number(i + 1)) {
            num = ele.bindingEvent[vi];
            ele.bindingEvent.splice(vi, 1);
          }
        });
        // 重新赋值
        let bindingEvent = [];
        ele.bindingEvent.forEach((val2) => {
          if (val2 > num) {
            bindingEvent.push(val2 - 1);
          } else {
            bindingEvent.push(val2);
          }
        });
        ele.bindingEvent = bindingEvent;
      });
    },
    selectAll() {
      // 搜证权限全选
      let peopleList = this.chapterPersonList.map((res) => {
        return res.personId;
      });
      if (peopleList.length === this.model.searchRolePersonIds.length) {
        this.model.searchRolePersonIds = [];
      } else {
        this.model.searchRolePersonIds = peopleList;
      }
    },
    clueDisabled(option) {
      // 选项禁用 关联线索-->搜证权限中只能出现一次
      let list1 = this.model.relevanceClueList;
      let list2 = this.model.searchRoleGoodsIds;
      let list3 = this.model.personChapterClueEventBoList.map((res) => {
        return res.needClueId;
      });
      let list4 = this.model.personChapterClueEventBoList.map((res) => {
        return res.getClueId;
      });
      let bol1 = list1.indexOf(option.id) !== -1;
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = list3.indexOf(option.id) !== -1;
      let bol4 = list4.indexOf(option.id) !== -1;
      return bol1 || bol2 || bol3 || bol4;
    },
    clueDisabledItem(option, item) {
      // 触发事件（条件）-->触发事件（结果）中只能出现一次
      let list1 = this.model.relevanceClueList;
      let list2 = this.model.searchRoleGoodsIds;
      let bol1 = list1.indexOf(option.id) !== -1;
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = item.needClueId === option.id;
      let bol4 = item.getClueId === option.id;
      return bol1 || bol2 || bol3 || bol4;
    },
    async save() {
      let fun = "addPersonClue";
      if (this.peopleClueId) {
        fun = "updatePersonClue";
        this.model.scenarioId = this.scenarioId;
        this.model.scenarioPerson = this.peopleId;
      }
      this.model.personChapterClueEventBoList &&
        this.model.personChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : 0;
        });
      const res = await this.getRequest[fun](this.model);
      this.$emit("afterSave", res);
    },
    clueDrawer(e) {
      // 优化需求，线索框点击线索打开抽屉
      this.$emit("openClueDrawer", e, 3);
    },
    peopleDrawer(e) {
      // 遍历出peopleClueId
      if (e === "0") return this.$message.warning("不能选全部人");
      if (e == this.peopleId) return this.$message.warning("不能选自己");
      let peopleClueId = 0;
      this.chapterPersonList.forEach((res) => {
        if (res.personId === e) {
          peopleClueId = res.id;
        }
      });
      this.$emit("openClueDrawer", e, 2, peopleClueId);
    },
  },
};
</script>

<style lang='scss' scoped>
.row {
  display: flex;
  margin-top: 10px;
}
.tab-title {
  min-width: 80px;
  height: 28px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 28px;
  text-align: center;
  margin-right: 20px;
}
.my-radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #7f76bd;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .my-radio-choice {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7f76bd;
  }
}
</style>