<template>
  <!-- 线索弹窗 -->
  <div class="clueDialog" v-loading="backgroundLoading">
    <div class="clueDialog-title">
      <div class="clueDialog-title-tip" v-if="haveBind()">
        *未关联{{ haveBind() }}。
      </div>
      <div class="tab-title">
        {{ model.clueName ? model.clueName : "名称" }}
      </div>
      <div style="position: absolute; right: 0">
        关联线索标签：
        <el-select v-model="model.clueLabelId" size="mini">
          <el-option
            v-for="(option, oi) in labelList"
            :key="oi"
            :label="option.labelName"
            :value="option.id"
          >
          </el-option>
          <el-option label="待绑定标签" value="0"> </el-option>
        </el-select>
      </div>
    </div>
    <div style="height: 70vh; overflow-y: scroll">
      <div class="row">
        <div class="tab-title">名称</div>
        <el-input
          v-model="model.clueName"
          size="mini"
          style="width: 250px"
        ></el-input>
        <div
          v-if="model.clueBelong"
          style="
            margin-left: 20px;
            color: #7f76bd;
            display: flex;
            align-items: center;
            font-weight: bold;
          "
        >
          {{
            model.clueBelong == 3
              ? "关联线索："
              : model.clueBelong == 2
              ? "关联人身："
              : "关联地点："
          }}
          <span
            style="cursor: pointer"
            v-for="(link, li) in model.aboveClueIds"
            :key="li"
            @click="linkClick(link)"
          >
            {{ linkName(link).name }}
          </span>
        </div>
      </div>

      <div class="row" style="align-items: center">
        <div class="tab-title">图片</div>
        <choice-image
          :viewUrl="model.pictureVo.picturePath"
          @surePush="
            (res) => {
              model.pictureVo = res;
            }
          "
        />
        <i
          v-if="model.pictureVo.picturePath"
          @click="model.pictureVo = { id: 0, picturePath: '' }"
          class="el-icon-close"
          style="cursor: pointer; margin-left: 10px"
        ></i>
      </div>

      <div class="row">
        <div class="tab-title">行动点数</div>
        <el-input-number
          v-model="model.expendPoint"
          size="mini"
          controls-position="right"
        ></el-input-number>
      </div>

      <div class="row">
        <div class="tab-title">描述</div>
        <el-input
          v-model="model.clueSynopsis"
          style="width: 600px"
          type="textarea"
        ></el-input>
      </div>

      <div class="row" style="align-items: center">
        <div class="tab-title">搜证权限</div>
        <div style="display: flex; align-items: center">
          <div class="my-radio">
            <div class="my-radio-choice"></div>
          </div>
          <el-select
            style="margin-right: 10px"
            size="mini"
            v-model="model.searchRolePersonIds"
            multiple
            @click-tag="peopleDrawer"
            default-first-option
            placeholder="请选择人物"
          >
            <el-button type="text" @click="selectAll" style="padding: 10px">
              全选
            </el-button>
            <el-option
              v-for="item in chapterPersonList"
              :key="item.personId"
              :label="item.personName"
              :value="item.personId"
            >
            </el-option>
          </el-select>

          <div
            class="my-radio"
            @click="model.isSearchClue = !model.isSearchClue"
          >
            <div class="my-radio-choice" v-if="model.isSearchClue"></div>
          </div>
          <el-select
            style="margin-right: 10px"
            size="mini"
            v-model="model.searchRoleGoodsIds"
            multiple
            default-first-option
            @click-tag="clueDrawer"
            @change="
              (e) => {
                model.isSearchClue = e && e.length;
              }
            "
            filterable
            placeholder="请选择线索（可以公开）"
          >
            <el-option
              v-for="option in allClueList"
              :key="option.id"
              :disabled="clueDisabled(option)"
              :label="option.clueName"
              :value="option.id"
            >
              <span style="float: left">{{ option.clueName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px"
                >第{{ option.chapterNum }}幕</span
              >
            </el-option>
          </el-select>

          <div
            class="my-radio"
            @click="model.haveQuestion = !model.haveQuestion"
          >
            <div class="my-radio-choice" v-if="model.haveQuestion"></div>
          </div>
          回答问题（对此线索深入调查）
        </div>
      </div>

      <div class="row">
        <div class="tab-title">公开程度</div>
        <openness v-model="model.openDegree" />
      </div>
      <div class="row">
        <div class="tab-title">音频设置</div>
        <ChoiceAudio
          :audioVO="model.scenarioAudioBaseDTO"
          @putAudio="putAudio"
          :playMode="1"
        />
      </div>
      <div class="flex-cc">
        <div style="width: 70%">
          <el-divider></el-divider>
        </div>
      </div>
      <div
        class="row"
        v-if="
          model.goodsChapterClueEventBoList &&
          model.goodsChapterClueEventBoList.length
        "
      >
        <div class="tab-title">触发按钮</div>
        <div class="flex-row flex-ac">
          <span>名称：</span>
          <el-input
            v-model="model.buttonName"
            placeholder="深入调查"
            style="width: 100px"
            size="mini"
            maxlength="4"
          />
          <span class="remark-text m-l-5"
            >(默认显示"深入调查"，自定义名称需要4字或以下)</span
          >
        </div>
      </div>
      <!-- 权限-回答问题 -->
      <div v-if="model.haveQuestion" class="flex-row">
        <div class="tab-title">触发前提</div>
        <div>
          <div
            class="row"
            style="display: flex; align-items: center; margin-top: 0"
          >
            问题：<el-input
              size="mini"
              v-model="model.topicContent"
              style="width: 400px"
            ></el-input>
          </div>
          <div class="row" style="display: flex; align-items: center">
            答案：<el-input
              size="mini"
              v-model="model.topicAnswer[0]"
              style="width: 400px"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 事件列表 -->
      <div
        class="row"
        style="margin-top: 30px"
        v-for="(item, i) in model.goodsChapterClueEventBoList"
        :key="i"
      >
        <div class="tab-title">触发事件{{ i + 1 }}</div>
        <div style="width: 600px">
          <div style="display: flex; align-items: center">
            <div class="my-radio">
              <div class="my-radio-choice"></div>
            </div>
            <el-select
              style="margin-right: 10px"
              size="mini"
              v-model="item.scenarioPersonId"
              @click-tag="peopleDrawer"
              clearable
              placeholder="请选择人物"
            >
              <el-option :label="`所有人`" :value="`0`"> </el-option>
              <el-option
                v-for="option in chapterPersonList"
                :key="option.personId"
                :label="option.personName"
                :value="option.personId"
              >
              </el-option>
            </el-select>

            <div style="display: flex; align-items: center">
              <div class="my-radio" @click="item.haveClue = !item.haveClue">
                <div class="my-radio-choice" v-if="item.haveClue"></div>
              </div>
              <el-select
                style="margin-right: 10px"
                size="mini"
                v-model="item.needClueId"
                @click-tag="clueDrawer"
                @change="
                  (e) => {
                    item.haveClue = !!+e;
                  }
                "
                clearable
                placeholder="请选择线索（不能公开"
                filterable
              >
                <el-option
                  v-for="option in allClueList"
                  :key="option.id"
                  :disabled="clueDisabledItem(option, item)"
                  :label="option.clueName"
                  :value="option.id"
                >
                  <span style="float: left">{{ option.clueName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                    >第{{ option.chapterNum }}幕</span
                  >
                </el-option>
              </el-select>
              <span> 得到此线索时 </span>
            </div>
          </div>

          <div style="margin-top: 10px">
            得到：
            <el-select
              v-model="item.getClueId"
              style="margin-right: 10px"
              size="mini"
              clearable
              placeholder="请选择线索（可以公开）"
              @click-tag="clueDrawer"
              filterable
            >
              <el-option
                v-for="option in allClueList"
                :key="option.id"
                :disabled="
                  option.clueBelong !== 4 || clueDisabledItem(option, item)
                "
                :label="option.clueName"
                :value="option.id"
              >
              </el-option>
            </el-select>

            <el-input-number
              v-model="item.score"
              style="margin: 0 10px; width: 120px"
              size="mini"
              controls-position="right"
              :min="0"
              :max="10"
            >
            </el-input-number>
            <span style="line-height: 28px"> 分 </span>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="danger"
              circle
              icon="el-icon-close"
              @click="deleteEvent(item, i)"
              plain
            ></el-button>
          </div>
          <!-- <div style="margin-top: 10px; display: flex; align-items: flex-end"> -->
          <!-- <el-input v-model="item.eventContent" type="textarea" placeholder="请输入事件"></el-input> -->
          <!--  -->
          <!-- </div> -->
        </div>
      </div>

      <!-- 添加事件 -->
      <div style="display: flex; justify-content: center; margin-top: 30px">
        <el-button icon="el-icon-plus" @click="addEvent">添加事件</el-button>
      </div>

      <!-- 事件组 -->
      <div style="margin: 20px 0 0 100px">
        以下事件组满足单事件，其余组内事件同时触发：
      </div>
      <div style="margin: 10px 0 0 100px; display: flex; flex-wrap: wrap">
        <div
          v-for="(group, gi) in model.combinationEventsList"
          :key="gi"
          style="margin: 0 10px 10px 0; display: flex"
        >
          <el-select
            size="mini"
            v-model="group.bindingEvent"
            multiple
            default-first-option
            placeholder="请选择事件序号"
          >
            <el-option
              v-for="(option, oi) in model.goodsChapterClueEventBoList"
              :key="oi"
              :value="oi + 1"
              :label="oi + 1"
              :disabled="optionDisabled(oi + 1)"
            ></el-option>
          </el-select>
          <el-button
            style="margin-left: 10px"
            size="mini"
            type="danger"
            circle
            icon="el-icon-close"
            @click="deleteGroup(group, gi)"
            plain
          ></el-button>
        </div>
        <el-button size="mini" icon="el-icon-plus" @click="addEventGroups"
          >添加事件组</el-button
        >
      </div>

      <!-- 留白 -->
      <div style="height: 100px"></div>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-button
        v-loading="loading"
        :disabled="loading"
        @click="save"
        type="primary"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
import openness from "./openness";
import ChoiceAudio from "@compts/common/ChoiceAudio.vue";
export default {
  components: {
    choiceImage,
    openness,
    ChoiceAudio,
  },
  props: [
    "chapterId",
    "scenarioId",
    "chapterPersonList",
    "modelId",
    "clueList",
    "allClueList",
    "mapList",
    "labelList",
  ],
  data() {
    return {
      loading: false,
      backgroundLoading: false,
      peopleList: [],
      model: {
        chapterId: this.chapterId,
        clueLabelId: "0",
        clueName: "",
        clueScore: "", // 物品线索得分
        buttonName: "深入调查",
        pictureVo: {
          id: 0,
          picturePath: "",
        }, // 图片对象
        expendPoint: 1, // 行动点数
        clueSynopsis: "",
        searchRolePersonIds: [], // 搜证权限人物列表，允许触发的人，空则表示所有人
        searchRoleGoodsIds: [], // 搜证权限线索ID
        isSearchClue: false, // 是否需要选择线索
        haveQuestion: false, // 是否需要回答问题
        topicContent: "",
        topicAnswer: [],
        openDegree: [1], //公开程度选择数组
        goodsChapterClueEventBoList: [
          {
            id: "",
            scenarioPersonId: "0",
            needClueId: "",
            clueId: "",
            getClueId: "",
            haveClue: false, // 是否需要选择线索
            eventContent: "",
            score: 0,
            webId: 1,
          },
        ],
        combinationEventsList: [
          {
            bindingEvent: [],
          },
        ],
        scenarioAudioBaseDTO: null,
      },
    };
  },
  created() {
    if (this.modelId) {
      this.getClue();
    } else {
      // 优化需求，打开新线索窗口，默认搜证权限选中所有人，默认0条触发事件
      let list = this.chapterPersonList.map((res) => {
        return res.personId;
      });
      this.model.searchRolePersonIds = list;
      this.model.goodsChapterClueEventBoList = [];
    }
    // this.getLableId();
  },
  methods: {
    haveBind() {
      const isHaveImg = this.model.pictureVo.picturePath;
      const isHaveBind = this.model.clueBelong
      const arr = [];
      !isHaveImg && arr.push("图片");
      !isHaveBind && arr.push("地点/人身/线索");
      return arr.join("、");
    },
    dealParam(data) {
      data.scenarioAudioBaseDTO = data.scenarioAudioVO;
      this.$delete(data, "scenarioAudioVO");
      this.model = data;
    },
    putAudio(v) {
      this.model.scenarioAudioBaseDTO = v
        ? {
            playMode: v.playMode,
            materialAudioId: v.materialAudioId || v.id,
            url: v.audioUrl,
            audioTotalTime: v.audioTotalTime,
          }
        : (this.model.scenarioAudioBaseDTO = null);
    },
    async getClue() {
      this.backgroundLoading = true;
      const res = await this.getRequest.getClue(this.modelId);
      res.datas.goodsChapterClueEventBoList &&
        res.datas.goodsChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : "";
        });
      // this.dealParam(res.datas)
      this.model = res.datas;
      this.backgroundLoading = false;
    },
    addEvent() {
      // 添加触发事件
      let obj = this.$options.data().model.goodsChapterClueEventBoList[0];
      obj.webId = this.model.goodsChapterClueEventBoList.length + 1;
      this.model.goodsChapterClueEventBoList.push(obj);
    },
    deleteEvent(item, i) {
      // 删除触发事件， 同时删除所有事件组中存在的序号, webId重新排序
      this.model.goodsChapterClueEventBoList.splice(i, 1);
      this.model.goodsChapterClueEventBoList.forEach((ev, ei) => {
        ev.webId = ei + 1;
      });
      this.model.combinationEventsList.forEach((ele) => {
        let num = 0; // 被切割序号的值
        ele.bindingEvent.forEach((val, vi) => {
          if (val === Number(i + 1)) {
            num = ele.bindingEvent[vi];
            ele.bindingEvent.splice(vi, 1);
          }
        });
        // 重新赋值
        let bindingEvent = [];
        ele.bindingEvent.forEach((val2) => {
          if (val2 > num) {
            bindingEvent.push(val2 - 1);
          } else {
            bindingEvent.push(val2);
          }
        });
        ele.bindingEvent = bindingEvent;
      });
    },
    addEventGroups() {
      // 新增事件组
      let obj = this.$options.data().model.combinationEventsList[0];
      this.model.combinationEventsList.push(obj);
    },
    deleteGroup(group, gi) {
      // 删除事件组
      this.model.combinationEventsList.splice(gi, 1);
    },
    clueDisabled(option) {
      // 选项禁用 关联线索-->搜证权限中只能出现一次
      let list2 = this.model.searchRoleGoodsIds;
      let list3 = this.model.goodsChapterClueEventBoList.map((res) => {
        return res.needClueId;
      });
      let list4 = this.model.goodsChapterClueEventBoList.map((res) => {
        return res.getClueId;
      });
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = list3.indexOf(option.id) !== -1;
      let bol4 = list4.indexOf(option.id) !== -1;
      return bol2 || bol3 || bol4 || option.id === this.modelId;
    },
    clueDisabledItem(option, item) {
      // 触发事件（条件）-->触发事件（结果）中只能出现一次
      let list2 = this.model.searchRoleGoodsIds;
      let bol2 = list2.indexOf(option.id) !== -1;
      let bol3 = item.needClueId === option.id;
      let bol4 = item.getClueId === option.id;
      return bol2 || bol3 || bol4 || option.id === this.modelId;
    },
    optionDisabled(num) {
      // 选项是否已经被其他事件组选中，是则禁用
      let all = [];
      this.model.combinationEventsList.forEach((val) => {
        val.bindingEvent.forEach((val2) => {
          all.push(val2);
        });
      });
      if (all.indexOf(num) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    selectAll() {
      // 全选
      let peopleList = this.chapterPersonList.map((res) => {
        return res.personId;
      });
      if (peopleList.length === this.model.searchRolePersonIds.length) {
        this.model.searchRolePersonIds = [];
      } else {
        this.model.searchRolePersonIds = peopleList;
      }
    },
    async save() {
      this.loading = true;
      let fun = "addClue";
      if (this.modelId) {
        fun = "updateClue";
      }
      this.model.goodsChapterClueEventBoList &&
        this.model.goodsChapterClueEventBoList.forEach((item) => {
          item.getClueId = +item.getClueId ? item.getClueId : 0;
        });
      const res = await this.getRequest[fun](this.model).finally(() => {
        this.loading = false;
      });
      this.$emit("afterSave", res);

      // if (res.respCode === 0) {
      //   this.$message({
      //     message: "保存成功！",
      //     type: "success",
      //   });
      // } else {
      //   this.$message({
      //     message: res.respMsg,
      //     type: "error",
      //   });
      // }
      // this.loading = false;
    },
    clueDrawer(e) {
      // 优化需求，线索框点击线索打开抽屉
      this.$emit("openClueDrawer", e, 3);
    },
    peopleDrawer(e) {
      // 遍历出peopleClueId
      if (e === "0") return this.$message.warning("不能选全部人");
      let peopleClueId = 0;
      this.chapterPersonList.forEach((res) => {
        if (res.personId === e) {
          peopleClueId = res.id;
        }
      });
      this.$emit("openClueDrawer", e, 2, peopleClueId);
    },
    linkClick(e) {
      // 点击线索关联的链接
      if (this.model.clueBelong === 2) {
        let peopleId = 0;
        this.chapterPersonList.forEach((res) => {
          if (res.id === e) {
            peopleId = res.personId;
          }
        });
        this.$emit("openClueDrawer", peopleId, 2, e);
      } else if (this.model.clueBelong === 1) {
        this.$emit("openClueDrawer", e, 1);
      } else if (this.model.clueBelong === 3) {
        // 查询这个线索归属哪个位置
        let state = this.linkName(e).state;
        if (state == 1) {
          this.$emit("openClueDrawer", e, 1);
        } else if (state == 2) {
          let peopleId = 0;
          this.chapterPersonList.forEach((res) => {
            if (res.id === e) {
              peopleId = res.personId;
            }
          });
          this.$emit("openClueDrawer", peopleId, 2, e);
        } else if (state == 3) {
          this.$emit("openClueDrawer", e, 3);
        }
      }
    },
    linkName(id) {
      // 根据线索ID遍历出地点名或人身名
      let re = "";
      let state = 1;
      if (this.mapList) {
        this.mapList.forEach((res) => {
          if (res.clueId == id) {
            re = res.clueName;
            state = 1;
          }
        });
      }
      if (this.chapterPersonList) {
        this.chapterPersonList.forEach((res) => {
          if (res.id == id) {
            re = res.personName;
            state = 2;
          }
        });
      }
      if (this.allClueList) {
        this.allClueList.forEach((res) => {
          if (res.id == id) {
            re = res.clueName;
            state = 3;
          }
        });
      }
      return {
        state: state,
        name: re,
      };
    },
  },
};
</script>

<style lang='scss' scoped>
.row {
  display: flex;
  margin-top: 10px;
}
.tab-title {
  min-width: 80px;
  height: 28px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 28px;
  text-align: center;
  margin-right: 20px;
}
.my-radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #7f76bd;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .my-radio-choice {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7f76bd;
  }
}
.clueDialog {
  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    &-tip {
      position: absolute;
      left: 0;
      top: 32px;
      color: rgba(243, 148, 172, 1);
    }
  }
}
</style>