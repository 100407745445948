<template>
  <!-- 搜证设定结局 -->
  <div class="evidenceEnding">
    <div class="row">
      <div class="tab-title">总结局</div>
      <div style="margin-left: 20px; width: calc(100% - 80px)">
        <div style="display: flex; justify-content: flex-end">
          <edit-text
            :content="model.scenarioEnd"
            @surePush="
              (res) => {
                model.scenarioEnd = res;
              }
            "
          />
        </div>
        <div class="eb-text-area" v-html="model.scenarioEnd"></div>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="row">
      <div class="tab-title">故事真相</div>
      <div style="margin-left: 20px; width: calc(100% - 80px)">
        <div style="display: flex; justify-content: flex-end">
          <edit-text
            :content="model.scenarioFact"
            @surePush="
              (res) => {
                model.scenarioFact = res;
              }
            "
          />
        </div>
        <div class="eb-text-area" v-html="model.scenarioFact"></div>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="row">
      <div class="tab-title">条件结局</div>
    </div>
    <div style="color: #999999; font-size: 14px; margin-top: 10px">
      人物多选并选择“有”是“或”关系，选择“无”则是“和”关系（多选时所有人同时得分）
    </div>

    <div style="padding: 20px 0 0 20px">
      <div
        v-for="(item, i) in model.scenarioEndBoList"
        :key="i"
        style="margin-bottom: 40px"
      >
        <div class="row" style="align-items: center">
          <div
            style="
              border-left: 8px solid rgba(236, 234, 250, 1);
              padding-left: 10px;
            "
          >
            条件结局{{ i + 1 }}
          </div>
          <el-button
            style="margin-left: 10px"
            size="mini"
            type="text"
            icon="el-icon-close"
            @click="deleteJJ(item, i)"
            >删除结局</el-button
          >
        </div>
        <!-- 触发条件 -->
        <div class="row" style="margin-top: 20px">
          <div style="width: 117px; white-space: nowrap" class="row-title">
            触发条件：
          </div>
          <div>
            <div class="flex mb-1">
              以下剧终投票条件、线索条件中，需要满足
              <myswitch
                class="mr-2 ml-2"
                :borderRadius="`12px`"
                leftText="全部"
                rightText="任一"
                :leftValue="0"
                :rightValue="1"
                v-model="item.conditionStatus"
              />
              项就可达成此结局：
            </div>
            <div class="mb-2">一、剧终投票条件：</div>
            <div class="ml-6 mb-3 flex-ac">
              <span class="mr-2">选择了</span>
              <el-select
                size="mini"
                v-model="item.optionId"
                placeholder="请选择角色"
                style="width: 140px; margin-right: 10px"
              >
                <el-option
                  v-for="(option, oi) in scenarioEndOptionVoList"
                  :key="oi"
                  :label="option.optionContent"
                  :value="option.id"
                ></el-option>
              </el-select>
            </div>
            <div class="mb-2">二、线索条件：</div>
            <div class="row ml-5" style="width: calc(100% - 100px)">
            <!-- <div class="row" style="width: calc(100% - 100px)"> -->
              <div class="row" style="width: 150px">
                <myswitch
                  :borderRadius="`12px`"
                  :leftText="`同时`"
                  :rightText="`分别`"
                  v-model="item.incidentRelation"
                />
                <span style="margin: 2px 0 0 10px">满足：</span>
              </div>

              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  width: calc(100% - 150px);
                "
              >
                <div
                  style="margin: 0 10px 10px 0"
                  class="row"
                  v-for="(tj, j) in item.scenarioEndRelationBoList"
                  :key="j"
                >
                  <el-select
                    size="mini"
                    v-model="tj.scenarioPersonId"
                    placeholder="请选择角色"
                    style="width: 140px; margin-right: 10px"
                    multiple
                  >
                    <!-- <el-option :label="`所有人`" :value="'0'"></el-option> -->
                    <el-option
                      v-for="(option, oi) in peopleList"
                      :key="oi"
                      :label="option.personName"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                  <myswitch
                    :leftText="`有`"
                    :rightText="`无`"
                    v-model="tj.isOwn"
                    style="margin-right: 10px"
                  />
                  <el-select
                    style="margin-right: 10px"
                    size="mini"
                    v-model="tj.chapterClueId"
                    filterable
                    placeholder="请选择线索"
                  >
                    <el-option
                      v-for="option in allClueList"
                      :key="option.id"
                      :label="option.clueName"
                      :value="option.id"
                    >
                      <span style="float: left">{{ option.clueName }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >第{{ option.chapterNum }}幕</span
                      >
                    </el-option>
                  </el-select>

                  <el-button
                    style="margin-left: 10px; height: 28px"
                    size="mini"
                    type="danger"
                    circle
                    icon="el-icon-close"
                    @click="deleteTJ(item, i, tj, j)"
                    plain
                  ></el-button>

                  <span style="margin-left: 10px">
                    {{
                      item.scenarioEndRelationBoList.length - 1 === j
                        ? "。"
                        : "，"
                    }}
                  </span>
                </div>

                <el-button
                  style="margin-left: 10px"
                  size="mini"
                  type="text"
                  icon="el-icon-circle-plus-outline"
                  @click="addTJ(item, i)"
                  >添加条件</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 结局得分 -->
        <div class="row" style="margin-top: 10px">
          <div class="row-title">结局得分：</div>
          <div
            style="width: calc(100% - 100px); display: flex; flex-wrap: wrap"
          >
            <div
              v-for="(df, k) in item.scenarioEndScoreBoList"
              :key="k"
              style="display: flex; align-items: center; margin: 0 10px 10px 0"
            >
              <el-select
                style="width: 140px"
                size="mini"
                v-model="df.scenarioPersonId"
                multiple
                filterable
              >
                <!-- <el-option :label="`所有人`" :value="'0'"></el-option> -->
                <el-option
                  v-for="(option, oi) in peopleList"
                  :key="oi"
                  :label="option.personName"
                  :value="option.id"
                ></el-option>
              </el-select>
              <el-input
                v-model="df.score"
                style="width: 120px; margin-left: 10px"
                size="mini"
              >
                <template slot="append"> 分 </template>
              </el-input>

              <el-button
                style="margin-left: 10px"
                size="mini"
                type="danger"
                circle
                icon="el-icon-close"
                @click="deleteDF(item, i, df, k)"
                plain
              ></el-button>
            </div>

            <!-- 新增结局得分 -->
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="addDF(item, i)"
              >添加得分</el-button
            >
          </div>
        </div>

        <!-- 结局文本 -->
        <div style="display: flex; padding: 12px 0">
          <div class="row-title">结局文本：</div>
          <div style="width: calc(100% - 100px)">
            <div style="display: flex; justify-content: flex-end">
              <edit-text
                :content="item.scenarioEnd"
                @surePush="changejjwb($event, item, i)"
              />
            </div>
            <div class="eb-text-area" v-html="item.scenarioEnd"></div>
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; display: flex; justify-content: center">
        <el-button @click="addJJ" icon="el-icon-plus">新增结局</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import editText from "@/components/common/editText";
export default {
  components: {
    editText,
  },
  data() {
    return {
      allClueList: [],
      model: {
        scenarioId: localStorage.getItem("scenarioId"),
        scenarioEnd: "",
        scenarioEndBoList: [
          // 结局列表
          {
            incidentRelation: 1, // 1同时2分别
            scenarioEndRelationBoList: [
              // 条件列表
              {
                scenarioPersonId: [], // 人物ID 0代表所有人
                isOwn: 1, // 1有2无
                chapterClueId: "", // 线索ID
              },
            ],
            scenarioEndScoreBoList: [
              // 得分列表
              {
                scenarioPersonId: [], // 人物ID，0代表所有人
                score: 5, // 得分
              },
            ],
            scenarioEnd: "", // 结局文本
            scenarioFact: "", // 故事真相
          },
        ],
      },
      peopleList: [], // 人物列表
      scenarioEndOptionVoList: []  //投凶选项
    };
  },
  created() {
    this.getScenarioClue(); // 查询剧本的所有线索列表
    this.getPersonList(); // 获取人物列表
    this.getEnding(); // 获取结局
    this.getEndingScore()
  },
  methods: {
    async getEndingScore() {
      const res = await this.getRequest.getEndingScore(this.model.scenarioId);
      this.scenarioEndOptionVoList  = res.datas ? res.datas.scenarioEndVoteTopicBo.scenarioEndOptionVoList : []
    },
    async getScenarioClue() {
      const res = await this.getRequest.getScenarioClue(this.model.scenarioId);
      this.allClueList = res.datas.sort((x, y) => {
        return x.chapterNum - y.chapterNum;
      });
    },
    async getPersonList() {
      const res = await this.getRequest.getPersonList(this.model.scenarioId);
      this.peopleList = res.datas;
    },
    async getEnding() {
      try {
        const res = await this.getRequest.getEnding(this.model.scenarioId);
        if (res.respCode === 0) {
          this.model = res.datas;
        } 
      } catch {
         this.model = null
      }
    },
    addJJ() {
      // 新增结局
      let obj = this.$options.data().model.scenarioEndBoList[0];
      this.model.scenarioEndBoList.push(obj);
    },
    deleteJJ(item, i) {
      // 删除结局
      this.model.scenarioEndBoList.splice(i, 1);
    },
    addTJ(item, i) {
      // 添加条件
      let obj =
        this.$options.data().model.scenarioEndBoList[0]
          .scenarioEndRelationBoList[0];
      if (item.id) {
        obj.branchEndId = item.id;
      }
      this.model.scenarioEndBoList[i].scenarioEndRelationBoList.push(obj);
    },
    deleteTJ(item, i, tj, j) {
      // 删除条件
      this.model.scenarioEndBoList[i].scenarioEndRelationBoList.splice(j, 1);
    },
    addDF(item, i) {
      // 添加得分
      let obj =
        this.$options.data().model.scenarioEndBoList[0]
          .scenarioEndScoreBoList[0];
      if (item.id) {
        obj.branchEndId = item.id;
      }
      this.model.scenarioEndBoList[i].scenarioEndScoreBoList.push(obj);
    },
    deleteDF(item, i, df, k) {
      // 删除得分
      this.model.scenarioEndBoList[i].scenarioEndScoreBoList.splice(k, 1);
    },
    changejjwb(res, item, i) {
      // 结局文本
      this.model.scenarioEndBoList[i].scenarioEnd = res;
    },
    async save() {
      if(!this.model) return
      let fun = "addEnding";
      if (this.model.id) {
        fun = "updateEnding";
      }
      const res = await this.getRequest[fun](this.model);
      if (res.respCode === 0) {
        if (fun === "addEnding") {
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "更新成功！",
            type: "success",
          });
        }
        this.model = res.datas;
      } else {
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-divider {
  background: rgba(236, 234, 250, 1);
}
.tab-title {
  width: 80px;
  height: 24px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
}
.row {
  display: flex;
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197, 192, 224, 1);
  height: 250px;
  padding: 0 10px;
  white-space: pre-wrap;
}
.row-title {
  width: 80px;
  margin-right: 20px;
}
</style>