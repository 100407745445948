<template>
  <!-- 搜证设定 -->
  <div class="entryEvidence">
    <div class="top-bar">
      <div
        @click="changeChapter(item)"
        :class="`item ${
          type === 1 && item.id === chapterId ? 'item-choice' : ''
        }`"
        v-for="(item, i) in list"
        :key="i"
      >
        第{{ item.chapterNum }}幕
      </div>
      <div @click="type = 2" :class="`item ${type === 2 ? 'item-choice' : ''}`">
        结局
      </div>
      <div @click="type = 3" :class="`item ${type === 3 ? 'item-choice' : ''}`">
        剧终判断
      </div>
      <!-- 线索标签管理 -->
      <div style="position: absolute; right: 0">
        <el-button @click="labelDialog = true" size="small" style="width: 140px"
          >线索标签</el-button
        >
      </div>
      <!-- 线索标签弹窗 -->
      <el-dialog
        :visible.sync="labelDialog"
        :close-on-click-modal="false"
        top="5vh"
      >
        <clue-label-dialog
          v-if="labelDialog"
          :scenarioId="scenarioId"
          :chapterId="chapterId"
          @clickClue="clickClue"
        />
      </el-dialog>
    </div>
    <el-card class="content" id="entryEvidence-card" v-if="type === 1">
      <evidence-act
        ref="evidenceAct"
        v-if="chapterId"
        :key="chapterId"
        :chapterId="chapterId"
        :scenarioId="scenarioId"
        :chapterNum="chapterNum"
        :mList="list"
      />
    </el-card>

    <!-- 结局 -->
    <el-card class="content" v-if="type === 2">
      <evidence-ending ref="evidenceEnding" />
    </el-card>
    <!-- 剧中判断 -->
    <el-card class="content" v-if="type === 3">
      <evidence-score ref="evidenceScore" />
    </el-card>

    <!-- 保存 -->
    <div
      style="
        position: absolute;
        bottom: -20px;
        right: 30px;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      "
    >
      <el-button
        v-if="isEdit"
        v-loading="loading"
        :disabled="loading"
        @click="save"
        style="
          border: 1px solid rgba(250, 225, 238, 1);
          background: rgba(250, 225, 238, 1);
          color: #333;
          font-weight: bold;
        "
        type="warning"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import evidenceAct from "./entryEvidence/evidenceAct";
import evidenceEnding from "./entryEvidence/evidenceEnding";
import evidenceScore from "./entryEvidence/evidenceScore";
import clueLabelDialog from "./components/clueLabelDialog";
export default {
  components: {
    clueLabelDialog,
    evidenceAct,
    evidenceEnding,
    evidenceScore,
  },
  data() {
    return {
      scenarioId: "",
      chapterNum: 1, //当前幕下标
      chapterId: 0, // 幕ID
      type: 1, // 1幕2结局3剧终判断
      list: [], // 幕列表
      labelDialog: false,
      labelList: [],
      loading: false,
    };
  },
  created() {
    this.scenarioId = localStorage.getItem("scenarioId");
    this.chapterList(); // 查询幕列表
    this.getLabel(); // 查询线索标签列表
    this.getallClueList();
   
  },
  destroyed() {
  },
  computed: {
    isEdit() {
      return (
        (sessionStorage.getItem("isEdit") &&
          JSON.parse(sessionStorage.getItem("isEdit"))) ||
        (sessionStorage.getItem("privateFlag") &&
          JSON.parse(sessionStorage.getItem("privateFlag")))
      );
    },
  },
  methods: {
    changeChapter(item) {
      this.type = 1;
      this.chapterId = item.id;
      this.chapterNum = item.chapterNum;
    },
    async getLabel() {
      this.$store.dispatch("scenario/getScenarioLabel", this.scenarioId);
    },
    async getallClueList() {
      this.$store.dispatch("scenario/getAllClueList", this.scenarioId);
    },
    async chapterList() {
      const res = await this.getRequest.chapterList(this.scenarioId);
      this.list = res.datas;
      this.chapterId = this.list[0].id;
    },
    clickClue(id) {
      this.$refs.evidenceAct && this.$refs.evidenceAct.openClueDrawer(id, 3);
    },
    async save() {
      this.loading = true;
      try {
        if (this.type === 2) {
          await this.$refs.evidenceEnding.save();
        } else if (this.type === 1) {
          await this.$refs.evidenceAct.save();
        } else if (this.type === 3) {
          await this.$refs.evidenceScore.save();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.entryEvidence {
  height: 100%;
  position: relative;
  .top-bar {
    display: flex;
    position: relative;
    .item {
      margin-right: 10px;
      padding: 4px;
      cursor: pointer;
      min-width: 80px;
      text-align: center;
    }
    .item-choice {
      background: rgba(127, 118, 189, 1);
      color: #fff;
      border-radius: 4px;
    }
  }
  .content {
    margin-top: 10px;
    height: calc(100% - 8px);
    overflow-y: scroll;
  }
}
</style>