<template>
<!-- 公开程度组件 -->
  <div style="display: flex">

    <div
      v-for="(item, i) in list" :key="i"
      style="display: flex; align-items:center; margin-right: 20px; cursor: pointer"
      @click="choice(item)"
    >
      <div class="my-radio">
        <div class="my-radio-choice" v-if="isChoice(item)"></div>
      </div>
      <div>
        {{ item.name }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      arr: this.value, // 选中的ID数组
      list: [
        {
          id: 1,
          name: '直接公开'
        },
        {
          id: 2,
          name: '玩家公开'
        },
        {
          id: 3,
          name: '玩家转交'
        },
        {
          id: 4,
          name: '仅玩家私有'
        }
      ],
    }
  },
  watch: {
    value (val) {
      this.arr = val
    },
    arr (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    choice (item) {
      // 选择， 分为1， 23， 4
      if (this.arr.indexOf(item.id) === -1) {
        if (item.id === 1 || item.id === 4) {
          this.arr = [item.id]
        } else {
          this.arr = [2, 3]
        }
      } else {
        // 如果存在可以反选
        if (item.id === 2 || item.id === 3) {
          if (this.arr.length === 1) return
          let index = this.arr.indexOf(item.id)
          this.arr.splice(index, 1)
        }
      }
    },
    isChoice (item) {
      // 是否被选中
      if (this.arr.indexOf(item.id) === -1) return false
      return true
    }
  }
}
</script>

<style lang='scss' scoped>
  .my-radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #7F76BD;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .my-radio-choice {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #7F76BD;
    }
  }
</style>